const compress =(strokes)=>{

    let compressed = []

    let currentStrokeSet, currentColor, currentWidth
    strokes.forEach( (stroke, ii) =>{
        if (ii === 0 || currentColor !== stroke.c || currentWidth !== stroke.w ){
            // Start a new
            if (currentStrokeSet){
                compressed.push({
                    c: currentColor, 
                    w: currentWidth,
                    s: currentStrokeSet
                })
            }
            currentColor = stroke.c
            currentWidth = stroke.w
            currentStrokeSet = []
        }

        let mx = Math.trunc(stroke.m[0])
        let my = Math.trunc(stroke.m[1])

        let lx = Math.trunc(stroke.l[0])
        let ly = Math.trunc(stroke.l[1])

        if (mx === lx && my === ly){
            // Ignore
        } else {
            currentStrokeSet.push([mx,my,lx,ly])
        }
        
    })        

    if (strokes.length !== 0 ){
        compressed.push({
            c: currentColor, 
            w: currentWidth,
            s: currentStrokeSet
        })
    }

    // const uncompressedLen = JSON.stringify(strokes).length
    // const compressedLen = JSON.stringify(compressed).length
    
    // console.log( "Uncompressed", uncompressedLen )
    // console.log( "Compressed", compressedLen )
    // console.log( "% size", compressedLen / uncompressedLen )

    return compressed
}

const inflate =(compressed)=>{

    let infalted  = []

    compressed.forEach( strokeSet =>{
        strokeSet.s.forEach( stroke =>{
            infalted.push({
                c: strokeSet.c,
                w: strokeSet.w,
                m: [stroke[0],stroke[1]],
                l: [stroke[2],stroke[3]],
            })
        } )
    } )

    return infalted
}

export default {
    compress, inflate
}