import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import CircularProgress from '@material-ui/core/CircularProgress';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { Auth } from "aws-amplify";
import { withRouter } from "react-router";
import { API } from "aws-amplify";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';

import {useHistory} from 'react-router-dom'

function getSteps() {
    return ['Sign Up', 'Room Name', 'Display Name', 'Account'];
}

const Signup =(props)=>{

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const emailPasswordForm=()=>{
        return (
        <React.Fragment>
            <StepHeader> Create an account </StepHeader>
                <div>
                    <StepHeader style={{
                        color: "#ffc629", fontWeight: 100,
                        marginBottom: "0px"}}> 
                        FREE for 7 days 
                    </StepHeader>
                    <StepSubHeader> No payment required  </StepSubHeader>
                    <StepDesc style={{fontSize:"12px", padding:"5px 0px", color:"rgb(180,180,180)"}}> You will receive an email to enter your billing information
                         at the end of your trial.  </StepDesc>
                </div >
            <form onSubmit={ async e=>{
                let ok = verifyAccount(e)
                if (ok){
                    ok = await submitAccount()
                    if (ok){
                        await onComplete()
                    }
                }
            }}>

                <TextField
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    name='email'
                    onChange={e => setEmail(e.target.value.trim())}
                    value={email}
                    style={{ padding: "10px 0px" }}
                    autoFocus
                    label="Email" />
                <br />
                <TextField
                    type="password"
                    variant="outlined"
                    name='password'
                    style={{ padding: "10px 0px" }}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    label={"Password"} />
                <br />
                <TextField
                    type="password"
                    variant="outlined"
                    name='confirmPassword'
                    style={{ padding: "10px 0px" }}
                    onChange={e => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    label="Confirm Password" />
                <br />

                {errorString.length > 0 && <ErrorDiv>
                    {errorString}
                </ErrorDiv>}

                <TofS>
                    By clicking SIGN UP, you agree to our {" "}
                    <a style={{ color: "rgb(180, 180, 180)" }} href="https://drawpoly.com/termsofservice" rel="noopener noreferrer" target="_blank">Terms of Use</a>
                    {" "} and {" "}
                    <a style={{ color: "rgb(180, 180, 180)" }} href="https://drawpoly.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                </TofS>

                <div style={{ margin: "20px 0px" }}>
                    { isLoading && <LoadingWrapper>
                        <CircularProgress size={20} thickness={5}/>
                      </LoadingWrapper>
                    }
                    <Button variant="contained" type="submit" color="primary" 
                        style={{fontWeight:"bold"}}>
                        Sign up
                    </Button>
                </div>

                
            </form>
               

        </React.Fragment>)
    }

    const roomNameForm=()=>{
        return (
            <React.Fragment> 
                <StepHeader> Claim your private room </StepHeader>
                <StepDesc> This can be changed later  </StepDesc>
                <form onSubmit={verifyRoomName}>
                    <TextField
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        name='RoomName'
                        style={{ padding: "10px 0px" }}
                        onChange={e => setRoomName(e.target.value)}
                        value={roomName}
                        autoFocus
                        label={"Room Name"} />
                    <br />

                    {errorString.length > 0 && <ErrorDiv>
                        {errorString}
                    </ErrorDiv>}

                    <div style={{ margin: "20px 0px" }}>
                    { isLoading && <LoadingWrapper>
                        <CircularProgress size={20} thickness={5}/>
                      </LoadingWrapper>
                    }
                    <Button variant="contained" type="submit" color="primary" onClick={(e)=>{
                        verifyRoomName(e).then( success=>{
                            if (success) handleNext()
                        })
                    }}
                        style={{fontWeight:"bold"}}>
                        Next
                    </Button>
                </div>
                </form>
            </React.Fragment >
        )
    }

    const drawpolyProForm = () => {
        return (
            <React.Fragment>
                <StepHeader> <span style={{ color: "#ffc629" }}> DRAWPOLY </span> <span style={{ color: "#2958FF"}}> PRO</span> </StepHeader>
                <StepHeader style={{ fontSize: "25px", color: "rgb(150,150,150)" }}> $4.99 USD/mo </StepHeader>
                <StepDesc > or $49.99 billed annually </StepDesc>

                <StepHeader style={{
                    color: "#ffc629", fontWeight: 100,
                    marginBottom: "0px"
                }}>
                    FREE for 7 days
                    </StepHeader>
                <StepSubHeader> No payment required  </StepSubHeader>
                <List style={{padding:"0px, 100px"}}>
                    <ListItem> 
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Private room" secondary="Create a private room with a magic word to enter" /> 
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Custom Words" secondary="Add your own custom categories and words " />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon> 
                        <ListItemText primary="Admin Controls" secondary="Only you can start and end the game" /> 
                    </ListItem>
                </List>
                <Button variant="contained" type="submit" color="primary" onClick={() => handleNext()}
                    style={{ fontWeight: "bold" }}>
                    Start FREE trial
                    </Button>
            </React.Fragment >
        )
    }


    const displayNameForm = () => {
        return (
            <React.Fragment>
                <StepHeader> Choose a display name </StepHeader>
                <StepDesc> Other players will see this name</StepDesc>

                <form onSubmit={verifyDisplayName}>

                    <TextField
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        name='DisplayName'
                        onChange={e => setDisplayName(e.target.value.trim())}
                        value={displayName}
                        style={{ padding: "10px 0px" }}
                        autoFocus
                        label="Display Name" />                    
                    <br />

                    {errorString.length > 0 && <ErrorDiv>
                        {errorString}
                    </ErrorDiv>}

                    <div style={{ margin: "20px 0px" }}>
                        {isLoading && <LoadingWrapper>
                            <CircularProgress size={20} thickness={5} />
                        </LoadingWrapper>
                        }
                        <Button variant="contained" type="submit" color="primary" onClick={(e) => {
                            verifyDisplayName(e).then(success => {
                                if (success) handleNext()
                            })
                        }}
                            style={{ fontWeight: "bold" }}>
                            Next
                    </Button>
                    </div>
                </form>
            </React.Fragment >
        )
    }

    // const magicWordForm = () => {
    //     return (
    //         <React.Fragment>
    //             <StepHeader> Set a magic word to enter your room </StepHeader>
    //             <StepDesc> This will make your room private </StepDesc>

    //             <form onSubmit={verifyMagicWord}>

    //                 <TextField
    //                     inputProps={{
    //                         autoCapitalize: 'none',
    //                     }}
    //                     variant="outlined"
    //                     name='MagicWord'
    //                     onChange={e => setMagicWord(e.target.value.trim())}
    //                     value={magicWord}
    //                     style={{ padding: "10px 0px" }}
    //                     autoFocus
    //                     label="Magic Word" />
    //                 <br />

    //                 {errorString.length > 0 && <ErrorDiv>
    //                     {errorString}
    //                 </ErrorDiv>}

    //                 <div style={{ margin: "20px 0px" }}>
    //                     {isLoading && <LoadingWrapper>
    //                         <CircularProgress size={20} thickness={5} />
    //                     </LoadingWrapper>
    //                     }
    //                     <Button variant="contained" type="submit" color="primary" onClick={(e) => {
    //                         verifyMagicWord(e).then(success => {
    //                             if (success) handleNext()
    //                             })
    //                         }}
    //                         style={{ fontWeight: "bold" }}>
    //                         Next
    //                 </Button>
    //                 </div>
    //             </form>
    //         </React.Fragment >
    //     )
    // }

    const getStepContent=(stepIndex)=> {
        switch (stepIndex) {
            case 0:
                return drawpolyProForm()
            case 1:
                return roomNameForm()
            // case 2:
            //     return magicWordForm()
            case 2:
                return displayNameForm()
            case 3:
                return emailPasswordForm()
            default:
                return 'Unknown stepIndex';
        }
    }


    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [displayName, setDisplayName] = useState('')
    const [roomName, setRoomName] = useState('')
    const [magicWord, setMagicWord] = useState('')


    const [isLoading, setLoading] = useState(false)
    const [errorString, setErrorString] = useState("")
    const history = useHistory()

    const onComplete=async e=>{
        await props.userHasAuthenticated(true);
        history.push("/")
    }

    // const submit= async e =>{
    //     e.preventDefault()
    //     setErrorString("")
    //     setLoading(true)

    //     if (email.length === 0){
    //         setErrorString("Email required")
    //         setLoading(false)
    //         return false 
    //     }
    //     if (password.length === 0 || password !== confirmPassword){
    //         setErrorString("Passwords do not match")
    //         setLoading(false)
    //         return false
    //     }
    //     if (password.length < 6){
    //         setErrorString("Passwords must have atleast 6 characters")
    //         setLoading(false)
    //         return false
    //     }
        
    //     try {
    //         await Auth.signUp({
    //             username: email,
    //             password: password
    //         });
    //         setLoading(false)
    //     } catch (e) {
    //         if (e.code == "InvalidParameterException"){
    //             setErrorString("Invalid email or password")
    //         }else if (e.code === "UsernameExistsException"){
    //             setErrorString("An account already exists for that email")
    //         } else {
    //             console.log(e)
    //             alert(e.message);
    //         }
    //         setLoading(false)
    //         return false
    //     }

    //     return true
    // }

    const verifyRoomName = async e => {
        e.preventDefault()
        setErrorString("")
        setLoading(true)

        if (roomName.length === 0) {
            setErrorString("Room name required")
            setLoading(false)
            return false
        }

        try {
            let result = await API.get("api", "/rooms/" + encodeURI(roomName))
            console.log(result)
            if (result.isPrivate === true && result.isAdmin === false) {
                console.log("Room name taken")
                setLoading(false)
                setErrorString("Room name taken, please choose a unique room name")
                return false
            }
        } catch (error) {
            setLoading(false)
            setErrorString("Room name taken, please choose a unique room name")
            return false
        }

        setLoading(false)

        return true
    }

    const verifyAccount = (e)=>{
        e.preventDefault()
        setErrorString("")
        setLoading(true)

        if (email.length === 0) {
            setErrorString("Email required")
            setLoading(false)
            return false
        }
        if (password.length === 0 || password !== confirmPassword) {
            setErrorString("Passwords do not match")
            setLoading(false)
            return false
        }
        if (password.length < 6) {
            setErrorString("Passwords must have atleast 6 characters")
            setLoading(false)
            return false
        }

        setLoading(false)
        return true
    }

    const submitAccount = async e =>{
        setLoading(true)
        try {
            // Create the account
            try {
                await Auth.signUp({
                    username: email,
                    password: password
                });
            } catch (e) {
                if (e.code == "InvalidParameterException") {
                    setErrorString("Invalid email or password")
                } else if (e.code === "UsernameExistsException") {
                    setErrorString("An account already exists for that email")
                } else {
                    console.log(e)
                    alert(e.message);
                }
                setLoading(false)
                console.log("Create Account Failed")
                return false
            }

            // Sign in
            await Auth.signIn(email, password);
        
            // Submit settings
            const settings = {
                DisplayName: displayName,
                RoomName: roomName,
                Categories: [],
                Password: magicWord,
                AutoCheckGuess: false,
            }
    
            let settingsResult = await API.put("api", `/settings`, {
                body: {
                    ...settings
                }
                })
            console.log("Settings result", settingsResult)
        
        } catch (error) {
            setLoading(false)
            setErrorString("Error creating account")
            return false
        }

        setLoading(false)
        setErrorString("")

        return true
    }

    const verifyDisplayName = async e => {
        e.preventDefault()
        setErrorString("")
        setLoading(true)

        if (displayName.length === 0) {
            setErrorString("Display name required")
            setLoading(false)
            return false
        }

        setLoading(false)
        return true
    }

    // const verifyMagicWord = async e => {
    //     e.preventDefault()
    //     setErrorString("")
    //     setLoading(true)

    //     setLoading(false)
    //     return true
    // }

    

    return (
        <Wrapper>
            <SectionWrapper>
                <Section>
                <div >{getStepContent(activeStep)}</div>
                </Section>
                <Stepper style={{margin: '10px 0px'}} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </SectionWrapper>
            {activeStep === 0 &&
            <React.Fragment>
                <OR>OR</OR>
                <SectionWrapper style={{ margin: "10px", background: 'none', border: 'none' }}>
                    <Section style={{ margin: "10px", color: "rgb(180,180,180)" }}>
                        Already have an account?
                    {/* <br></br> */}
                        <Button type="button" variant="outlined"
                            onClick={() => {
                                props.history.push("/login");
                                window.scrollTo(0, 0)
                            }}
                            style={{ height: "40px", margin: "0px 10px", borderColor: 'rgb(220,220,220)' }}>
                            Log in
                    </Button>
                    </Section>
                </SectionWrapper>
            </React.Fragment>
            }
        </Wrapper>
    )
}

const LoadingWrapper = styled.div`
    display: inline-block;
    margin-right: 20px;
`

const TofS = styled.div`
    user-select:none;
    margin: 20px 5px;
    margin-bottom: 0px;
    font-size: 12px;
    color:rgb(180,180,180);
`

const Wrapper = styled.div`
    text-align: center;
    font-size: 20px;
    /* font-family: 'Baloo Chettan 2', cursive; */
    background-color: #f8f8f8;
`

const ErrorDiv = styled.div`
    color: red;
    font-size: 16px;
    padding: 0px 15px;
`

const StepHeader = styled.div`
    font-size: 20pt;
    margin: 20px 0px;
    font-weight: bold;
    font-family: 'Baloo Chettan 2', cursive;
    color: rgb(100,100,100);
`

const StepSubHeader = styled.div`
    font-size: 12pt;
    color: #ffc629;
    margin-bottom: 20px;
    /* font-weight: bold; */
`
const StepDesc = styled.div`
    font-size: 13pt;
    margin: 10px 40px;
    color: rgb(150,150,150);
`

const OR = styled.div`
    font-size: 26;
    margin-bottom: 40px;
    margin-top: 40px;
    color: rgb(180,180,180);
`

const Section = styled.div`
    margin-top: 20px;
    padding: 5px;    
`

const SectionWrapper = styled.div`
    background-color: white;
    /* padding: 20px 15%; */
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    
`

export default withRouter(Signup)