export const createInitialState =()=> {
    return {
        name:"",
        room:"",
        drawingStrokes:[],
        mute:false,
        customCategories:[],
        roomData: {},
        adminMagicPassword: "",
        autoCheckGuess: false,
        accountState: undefined
    }
}