import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import Lottie from "../Lottie";
import * as animationLoseData from "../animations/lose.json"
import CanvasPlayer from './canvasPlayer';


class TurnGiveUp extends React.Component{
    constructor(props){
        super(props)
        this.state={
            animDone:false
        }
    }

    focusGuess = () => {
        // setTimeout(() => {
        //     if (this.guessWrapper) {
        //         this.guessWrapper.scrollIntoView({ behavior: 'smooth' })
        //     }
        // }, 1000);
    }


    render(){
        const { drawer, word, guesses, reason, width, height } = this.props.turnFinishedData
        const drawingStrokes = this.props.strokes
        const scale = this.props.scale  
        return (
            <TurnWinner>
                <WinnerText> {drawer} {reason}! </WinnerText>
                {word ?
                    <React.Fragment>
                        <WinnerWords>
                            {drawer}'s {this.props.phrase} was {word}
                        </WinnerWords>
                        <CanvasPlayer strokes={drawingStrokes} width={width} height={height} scale={scale} onComplete={this.focusGuess}
                            active_background_color={this.props.active_background_color} />
                        {!this.state.animDone &&
                            <FailAnim>
                                <Lottie animation={animationLoseData.default} loop={false} onComplete={()=>this.setState({animDone:true})} />
                            </FailAnim>
                        }
                        <Guesses ref={el => this.guessWrapper = el}>
                        {this.props.showGuesses(guesses)}
                        </Guesses>
                    </React.Fragment>
                    :
                    <FailAnimNoCanvas>
                        <Lottie animation={animationLoseData.default} loop={false} />
                    </FailAnimNoCanvas>
                }

                <Button variant="contained" type="submit" style={{
                    height: "40px", marginTop: "10px",
                    position: "absolute", top: "0px", right: "10px",
                    background: "#ffc629", color: "white", fontWeight: 900, borderRadius: "10px"
                }}
                    onClick={this.props.next}
                >
                    Next
                </Button>
            </TurnWinner>
        )
    }
}

export default TurnGiveUp

const Guesses = styled.div`
    margin: 10px;
`

const TurnWinner = styled.div`
  margin: auto auto;
  margin-bottom: 20px;
  width: 100%;
  border: solid #ffc629 2px;
  background-color: white;
  border-radius: 20px;
  top:0;
  color: black;
  min-height: 420px;
`

const WinnerText = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin: 50px 0px;
    color: rgb(80,80,80);
    letter-spacing: 2px;
`

const FailAnim = styled.div`
  /* margin: -60px auto; */
  margin: 0 auto;
  position: absolute;
  /* left: 50%;
  margin-left: -240px;
  width: 480px; */
  width: 100%;
  top: 140px;
`

const FailAnimNoCanvas = styled.div`
  margin: 0 auto;
`

const WinnerWords = styled.div`
   margin: 40px;
`
