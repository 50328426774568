import React from 'react'
import styled from "styled-components"
import TextField from '@material-ui/core/TextField';
import Timer from "./timer"
class GuessInput extends React.Component{
    constructor(props){
        super(props)
        this.state={
            word : ''
        }
        // We want to avoid submitting twice, once from onBlur and once from Submit
        this.submitted = false 
    }

    submit=(e)=>{        
        e.preventDefault()

        let word = this.state.word.trim()

        if (word && !this.submitted) {
            console.log("Submitting guess", word)
            this.props.onSubmit({ word:word })
            this.submitted = true
        }
    }

    componentDidMount(){
        // setTimeout(() => this.inputRef.focus(), 150)
    }

    render(){
        return (
            <Wrapper>
                <Timer seconds={19} onTimeout={this.props.onTimeout}/>
                <form autoComplete={"off"} onSubmit={this.submit}>
                {this.props.num_words === 1 ?
                    < Text > Guess the word</Text>
                    :
                    <Text>Guess the phrase ({this.props.num_words} words)</Text>
                }
                <TextField
                    variant="outlined"
                    name='word'
                    autoFocus
                    // inputRef={ref => this.inputRef = ref}
                    inputProps={{
                        maxLength: 30,
                        autoComplete: false,
                    }}
                    onChange={(e) => this.setState({ word: (e.target.value) })}
                    onBlur={this.submit}
                    placeholder="ex. kitten" />
                <br/>
                {/* <Button variant="contained" type="submit" color="primary" style={{ height: "40px", margin: "10px" }}>
                    Submit
                </Button> */}
                <BuzzerStyle onClick={this.submit}>
                    Submit
                </BuzzerStyle>
                
                </form>
            </Wrapper>
        )
    }
}

export default GuessInput

const Wrapper = styled.div`
    text-align: center;
    font-family: "Nanum Gothic", sans-serif;
    user-select: none;
    font-size: 20px;
`
const Text = styled.div`
    margin: 5px;
    color: #2958FF;
    font-size: 22px;
    font-family: 'Baloo Chettan 2', cursive;
`

const BuzzerStyle = styled.div`
    letter-spacing: 2px;
    color:white;
    font-size: 20px;
    padding: 11px 0px;
    width: 120px;
    margin: 10px auto;
    background: #ffc629;
    font-weight: 900;
    border-radius: 20px;
    border: solid 4px #fffc82;
    font-family: 'Baloo Chettan 2', cursive;
`