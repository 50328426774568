import React, { Component } from 'react';
import styled from "styled-components"
import contrastColor from './contrastColor';


// NOTE ---------- IF CHANGING THESE COLORS, CHANGE THE contrastColor function too
const colors = [
  //red
  "#ea2332",
  "#76070f",

  "#fcc691",
  "#f16734",
  "#7b4a18",

  "#fef847",
  "#827b24",

  //green
  "#c1dea0",
  "#42b653",
  "#125b2c",

  "#74cff4",
  "#1e70b8",
  "#0c2654",

  //purple
  "#8982bb",
  "#642e8d",
  
  "#f39cc1",
  "#ea058a",

  "#fefefe",
  "#8d8d8d",
  "#101010",
]

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state={
      // active_color: colors[0]
    }
  }
  
  render() {

    return (
      <Wrapper2>
        {this.props.backgroundSwatches ? 
          <WrapperSquare>
            {colors.map(c => (
              <ColorSwatchSquare key={c + "color"} color={c} selected={c === this.props.active_color}
                onClick={() => {
                  this.props.handleColorChange(c)
                }} />
            ))}
          </WrapperSquare>
          :
          <Wrapper>
          {colors.map( c => (
            <ColorSwatch key={c+"color"} color={c} selected={c === this.props.active_color} 
            onClick={() =>{
              this.props.handleColorChange(c)}} />
          ))}
      
      </Wrapper>
    }
    </Wrapper2>
    );
  }
}
 
const Wrapper2 = styled.div`

  margin: 0px auto;
  z-index: 100;

`;

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(10, 33px);
  width: 330px;
  margin: 0px auto;
  z-index: 100;
`; 

const WrapperSquare = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 60px);
  width: 300px;
  margin: 0px auto;
  z-index: 100;
`; 

const ColorSwatch = styled.div`
  height: 30px;
  /* padding: 2px; */
  background-color: ${props=> props.color};
  /* border-radius: 0px; */
  border-color:  ${props => props.selected ? contrastColor(props.color) : 'grey' };
  border-width: ${props => props.selected ? '3px' : '0px' };
  border-style: solid;
  cursor: pointer;
`;

const ColorSwatchSquare = styled.div`
  height: 58px;
  margin: 2px;
  background-color: ${props => props.color};
  /* border-radius: 20px; */
  border-color:  ${props => props.selected ? contrastColor(props.color) : 'grey'};
  border-width: ${props => props.selected ? '3px' : '0px'};
  border-style: solid;
  cursor: pointer;
`;

export default ColorPicker;