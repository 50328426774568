import React from 'react'
import styled from "styled-components"
import ScrollContainer from 'react-indiana-drag-scroll'

var Styling = require('./styling.json')

const getFocusIndex =(turns, index)=>{
    if (turns.length === 0) return -1
    if (index !== -1){
        return index
    } else {
        return turns.length - 1
    }
}

class TurnTimeline extends React.Component {
    constructor(props){
        super(props)
        this.turnRefs = props.turns.map(gg=>React.createRef())
        this.state = {
            focusIndex : getFocusIndex(props.turns, props.index)
        }
    }

    componentDidMount(){
        
        setTimeout(() => {
            let target = this.turnRefs[this.state.focusIndex].current
            if (target){
                target.scrollIntoView({behavior: 'smooth'})
            }
        }, 1000);
    }
 
    turnData = (turn, currentTurnIndex, index)=>{
        if (currentTurnIndex === index ){
            return {word: "___", color: Styling.Gradients.YellowTheme , textColor:"white"}
        }else if(currentTurnIndex > index) {
            return { word: turn.word, color: Styling.Gradients.BlueTheme, textColor: "#86a0ff" } 
        }else{
            return { word: "___", color: Styling.Gradients.Grey, textColor:"white" }
        }
    }

    render(){
        let {turns, index} = this.props
        return (
            <Wrapper 
                vertical={false}
                horizontal={true}
                hideScrollbars={true}
                // ref={el=>this.scrollEl = el}
                >
            <ContentWrapper >
                {turns.map( (turn, ii)=>(
                        <TurnWrapper color={this.turnData(turn, index, ii).color} textColor={this.turnData(turn, index, ii).textColor} key={ii} ref={this.turnRefs[ii]}>
                        <TurnContent>
                            <TurnUser>
                                {turn.drawerName}
                            </TurnUser>
                            <TurnWord>
                                {this.turnData(turn, index, ii).word}
                            </TurnWord>
                        </TurnContent>
                    </TurnWrapper>
                ))}
            </ContentWrapper>
        </Wrapper>

        )
    }
}

    

export default TurnTimeline

const Wrapper = styled(ScrollContainer)`
    position: relative;
    width: 100%;
    margin-bottom:10px;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
`

const TurnWrapper = styled.div`
    margin-left: 10px;
    padding: 3px 10px;
    border-radius: 20px;
    background: ${props=>props.color};
    color: ${props => props.textColor};
    font-family: 'Baloo Chettan 2', cursive;
    flex: 0 0 auto;
    border-radius: 5px;
`

const TurnContent = styled.div`
    display: flex;
    flex-direction: column;
`

const TurnUser = styled.div`
    font-size: 12px;
    font-weight: normal;
`

const TurnWord = styled.div`
    font-size: 20px;
    font-weight: bolder;
`

