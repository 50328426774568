import ReactGA from "react-ga"
import config from "./config";

const trackEvent = (args)=>{
    const {category,action,label,value} = args
    if (config.googleTrackingId){
      ReactGA.event(args)
    }
    console.log("GoogleAnalyticsEvent", category, action, label, value)
  }

export default trackEvent