import React, { Component } from "react";
import styled from "styled-components";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { keyframes } from 'styled-components'
import trackEvent from '../TrackEvent'

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TwitterIcon,
    FacebookShareButton,
    EmailShareButton,
    TwitterShareButton,
    RedditShareButton,
    PinterestShareButton,
    LinkedinShareButton,
} from "react-share";


export default class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
        copied: false,
    }
  }

  trackShare=(endpoint)=>{
      trackEvent({
        category: "SinglePlayer", 
        action: "Share",
        label: endpoint
       })
  }

  render() {
    
    let shareUrl = this.props.shareUrl
    return (
        <React.Fragment>
        <Wrapper >
            <TitleBar>
                Share
            </TitleBar>
            <Grid>
                <Icon onClick={()=>this.trackShare("Facebook")}>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={"Guess what I'm drawing!"}
                        hashtag={"#drawpoly"} >
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>

                </Icon>
                <Icon onClick={()=>this.trackShare("Twitter")}>
                    <TwitterShareButton
                        url={shareUrl}
                        title={"Guess what I'm drawing! #drawpoly"} >
                        <TwitterIcon
                            size={40}
                            round={true} />
                    </TwitterShareButton>       
                </Icon>
                <Icon onClick={()=>this.trackShare("Reddit")}>
                    <RedditShareButton
                        url={shareUrl}
                        title={"Guess what I'm drawing!"}
                        windowWidth={660}
                        windowHeight={460}
                    >
                        <RedditIcon
                            size={40}
                            round={true} />
                    </RedditShareButton>    
                </Icon>
                <Icon onClick={()=>this.trackShare("Pinterest")}>
                    <PinterestShareButton
                        url={shareUrl}
                        media={"https://uploads-ssl.webflow.com/5eacc5c8eb6edcebd61ea2de/5eb0637d7e02b575f6fc1839_Drawpoly_opengraph.png"}
                    >
                        <PinterestIcon
                            size={40}
                            round={true} />
                    </PinterestShareButton>
                </Icon>
                <Icon onClick={()=>this.trackShare("Email")}>
                    <EmailShareButton
                        url={shareUrl}
                        subject={"Guess What I'm Drawing!"}
                        body="Click here to see my drawing" >
                        <EmailIcon
                            size={40}
                            round={true} />
                    </EmailShareButton>
                </Icon>
                <Icon onClick={()=>this.trackShare("Linkedin")}>
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon
                            size={40}
                            round={true} />
                    </LinkedinShareButton>
                </Icon>
            <Name onClick={()=>this.trackShare("Facebook")} >
                <FacebookShareButton
                    url={shareUrl}
                    quote={"Guess what I'm drawing!"}
                    hashtag={"#drawpoly"} >
                    Facebook
                </FacebookShareButton>
            </Name>
            <Name onClick={()=>this.trackShare("Twitter")}>
                <TwitterShareButton
                        url={shareUrl}
                        title={"Guess what I'm drawing! #drawpoly"} >
                    Twitter
                </TwitterShareButton>    
            </Name>
            <Name onClick={()=>this.trackShare("Reddit")}>
                <RedditShareButton
                        url={shareUrl}
                        title={"Guess what I'm drawing!"}
                        windowWidth={660}
                        windowHeight={460}
                    >
                    Reddit
                </RedditShareButton>   
            </Name>
            <Name onClick={()=>this.trackShare("Pinterest")}>
                <PinterestShareButton
                        url={shareUrl}
                        media={"https://uploads-ssl.webflow.com/5eacc5c8eb6edcebd61ea2de/5eb0637d7e02b575f6fc1839_Drawpoly_opengraph.png"}
                    >
                    Pintrest
                </PinterestShareButton>
            </Name>
            <Name>
                <EmailShareButton
                        url={shareUrl}
                        subject={"Guess What I'm Drawing!"}
                        body="Click here to see my drawing" >
                    Email
                </EmailShareButton>
            </Name>
            <Name onClick={()=>this.trackShare("Linkedin")}>
                <LinkedinShareButton url={shareUrl}>
                    LinkedIn
                </LinkedinShareButton>
            </Name>
            </Grid>

            <CopyUrl>
                <CopyToClipboard text={shareUrl}
                    onCopy={() => {
                        this.trackShare("Copy")
                        this.setState({ copied: true })
                    }}>
                    <Copy>
                        COPY
                    </Copy>
                </CopyToClipboard>
                <Url>
                    {shareUrl}
                </Url>
            </CopyUrl>

        </Wrapper>
        {this.state.copied &&
            <CopiedURL> Link copied to clipboard </CopiedURL> 
        }
        </React.Fragment>
    );
  }
}

const Animation4 = keyframes`
 0% {  opacity: 0;  }
 5% { opacity: 1;  }
 90% { opacity: 1;  }
 100% { opacity: 0; }
`

const Animation2 = keyframes`
 0% {  opacity: 0; transform: scale(0.7) }
 52% { opacity: 0; transform: scale(0.7) }
 75% { opacity: 1;  transform: scale(1.1) }
 100% {  transform: scale(1.0)}
`

const CopiedURL = styled.div`
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: white;
    background: rgba(100,100,100,0.8);
    border-radius: 3px;
    font-size: 12px; 
    padding: 5px 20px;
    opacity: 0;
    font-family: 'Baloo Chettan 2', cursive;
    animation-name: ${ Animation4};
    animation-duration: 3s;
    margin-bottom: 10px;
    margin-top: 5px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 50px);
    margin: 0px 0px;
`

const Icon = styled.div`
    margin: auto;
    cursor: pointer;
    transform: scale(1.0);
    animation-name: ${ Animation2};
    animation-duration: 2.1s;
    
`

const Name = styled.div`
    font-size: 9px;
    margin: auto;
    cursor: pointer;
`

const CopyUrl = styled.div`
    background: rgb(250,250,250);
    border: 1px solid rgb(230,230,230);
    border-radius: 4px;
    margin-top: 5px;   
`
const Url = styled.div`
    text-align: left;
    font-size: 10px;
    padding: 8px 5px;
    cursor: default;
`

const Copy = styled.div`
    float: right;
    font-size: 11px;
    padding: 8px 5px;
    padding-right: 30px;
    cursor: pointer;
    color: blue;
    transform: scale(1.0);
    animation-name: ${ Animation2};
    animation-duration: 2.1s;
`
const TitleBar = styled.div`
  text-align: left;
  font-size: 11px;
  margin-bottom: 5px;
`

const Wrapper = styled.div`
  background: white;
  padding: 10px;
  margin:auto;
  width: 320px;
  border: 0.5px solid rgb(230,230,230);
  /* transform: scale(1.0);
  animation-name: ${ Animation2};
  animation-duration: 2.1s; */
`;

