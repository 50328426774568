import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';


class EnterMagicWord extends React.Component{
    constructor(props){
        super(props)
        this.state={
            magicWord : "",
        }
    }

    submit=(e)=>{
        e.preventDefault()
        let {magicWord} = this.state

        // if (magicWord.trim().length > 0){
        this.props.onSubmit(magicWord.trim())
        // }
    }

    render(){
        return (
            <form onSubmit={this.submit}>
                <Wrapper>
                    <RoomStateText> This room is private </RoomStateText>
                    <Text>
                        Enter the magic word:
                    </Text>
                    <TextField
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        name='magicPassword'
                        onChange={e => this.setState({
                            magicWord: e.target.value.toLowerCase()
                        })}
                        value={this.state.magicWord}
                        style={{ padding: "10px 0px" }}
                        autoFocus
                        label="Magic Word" />
                    {this.props.errorString.length > 0 && (
                        <ErrorDiv>
                            {this.props.errorString}
                        </ErrorDiv>
                    )}
                    <ActionsWrapper>
                        <Button style={{color:"rgb(100,100,100)"}} 
                            onClick={this.props.onLeave} >
                            Cancel
                        </Button>
                        <Button style={{ color: "#2958ff", background: "white", border:"#2958ff"}} 
                            onClick={this.submit} 
                            type="submit" variant="outlined" color="white">
                            Done
                        </Button>
                    </ActionsWrapper>
                </Wrapper>
            </form>
        )
    }
}

const ErrorDiv = styled.div`
    color: red;
`

const Wrapper = styled.div`
    padding: 20px;
    text-align: center;
`

const Text = styled.div`
    margin-bottom: 20px;
`
const RoomStateText = styled.div`
    color: #2958FF;
    font-size: 25px;
    font-family: 'Baloo Chettan 2', cursive;
    margin-bottom: 10px;
    
`

const ActionsWrapper = styled.div`
    text-align: right;    
`

export default EnterMagicWord