import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import Lottie from "../Lottie";
import { keyframes } from 'styled-components'

import * as animationCloudsData from "../animations/clouds.json"


class ShowScoreBoard extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
    componentWillUnmount() {
        // if (this.closeTimer) {
        //     clearTimeout(this.closeTimer)
        //     this.closeTimer = null
        // }
    }

    render(){
        // this.closeTimer = setTimeout(() => {
        //     this.props.next()
        // }, 3000);
        const { winner, drawer, drawerPts } = this.props.turnFinishedData
        const { success, nextWord, orderedScores } = this.props
        return (
            <TurnWinner>
                <AnimClouds>
                    <Lottie animation={animationCloudsData.default} loop={true} />
                </AnimClouds>
                <WinnerText> <ResultsText> RESULTS </ResultsText> </WinnerText>
                {success ?
                    <WinnerPoints>
                        <NewPoints>
                            <span style={{ fontWeight: 'bolder', color: '#6bc558'}}> +2</span> for {winner}
                            <br />
                            <span style={{fontWeight: 'bolder', color: '#6bc558'}}> +{drawerPts} </span> for {drawer}
                        </NewPoints>
                    </WinnerPoints>
                    :
                    <WinnerPoints>
                        <NewPoints>
                            <span style={{ fontWeight: 'bolder', color: '#e7362e' }}> -1</span> for {drawer}
                        </NewPoints>
                    </WinnerPoints>
                }
                {this.props.drawScore(orderedScores)}
                <Button variant="contained" type="submit" style={{
                    height: "40px", marginTop: "10px",
                    position: "absolute", top: "0px", right: "10px",
                    background: "#ffc629", color: "white", fontWeight: 900, borderRadius: "10px"
                }}
                    onClick={this.props.next} >
                    {nextWord}
                </Button>

                <Button variant="contained" type="submit" style={{
                    height: "40px", marginTop: "10px",
                    position: "absolute", top: "0px", left: "10px",
                    background: "rgb(200,200,200)", color: "white", fontWeight: 900, borderRadius: "10px"
                }}
                    onClick={this.props.back} >
                    Back
                </Button>
            </TurnWinner>
        )
    }
}

export default ShowScoreBoard

const WinnerPoints = styled.div`
    margin: 20px;
    margin-top: -50px;
    z-index:1;
`


const pulseAnimation = keyframes`
 0% {  transform: scale(0.5) }
 50% {  transform: scale(1.2) }
 100% {  transform: scale(1.0)}
`

const NewPoints = styled.div`
    transform: scale(1.0);
    animation-name: ${ pulseAnimation };
    animation-duration: 0.8s;
`

const TurnWinner = styled.div`
  margin: auto auto;
  margin-bottom: 20px;
  width: 100%;
  border: solid #ffc629 2px;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 20px;
  top:0;
  z-index:-2;
  color: black;
`

const WinnerText = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin: 50px 0px;
    color: rgb(80,80,80);
    z-index:1;
    letter-spacing: 2px;
`

const ResultsText = styled.div`
    margin: 5px;
    letter-spacing: 2px;
    color: #2958FF;
    z-index:1;
    font-size: 40px;
    font-family: 'Baloo Chettan 2', cursive;
`

const AnimClouds = styled.div`
  margin: 0 auto;
  position: absolute;
  width: 100%;
  margin-left: -10px;
  z-index:-1;
  top: 0;
`

