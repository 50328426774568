import React from 'react'
import styled from "styled-components"
import { keyframes } from 'styled-components'
import TurnInput from './TurnInput';
import Timer from "./timer"
import Canvas from './canvas';
import CanvasPlayer from './canvasPlayer';
import BrushOptions from './brushOptions';
import BrushSetup from './brushSetup';

import Share from './share';

import Button from '@material-ui/core/Button';
import Lottie from "../Lottie";
import * as animationData from "../animations/colorLine.json"
import axios from "axios"

import trackEvent from "../TrackEvent"
import strokeCompress from "./StrokeCompress"
import ImageUtils from "./ImageUtils"

var wordList = require('./words.json')

var categories = [
    ...wordList.Categories.map(cc=>cc.Name),
    "Random"
]

const UserStates = {
    INTRODUCTION: 'INTRODUCTION',
    CATEGORY: 'CATEGORY',
    TURN_INPUT: "TURN_INPUT",
    DRAWING_SETUP: "DRAWING_SETUP",
    DRAWING: "DRAWING",
    DONE: "DONE",
}

class OnePlayer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            userState: UserStates.INTRODUCTION,
            replayComplete: false,
            showShareDialog: false,
            word: "",
            clue: "",
            shareLink: "",
            difficulty: "",
            hardWord: "",
            mediumWord: "",
            easyWord: "",
            gameCategory: "Random",
            width: 0,
            height: 0,
            drawingStrokes: [],
            active_brush: 5,
            active_color: '#101010',
            active_background_color: '#fefefe',
            bushOptionsOpen: false,
            brushSetupOpen: true,
        }
    }

    async componentDidMount() {

        trackEvent({
            category: "SinglePlayer", 
            action: "Start"
        })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

        
        if (this.closeTimer) {
            clearTimeout(this.closeTimer)
            this.closeTimer = null
        }
        
    }

    updateWindowDimensions=()=> {
        let width = Math.min(document.documentElement.clientWidth, 600) - 50
        let height = document.documentElement.clientHeight - 250
        if (height > width) {
            height = Math.min(height, 1.5 * width - 50)
        } else {
            // width = Math.min(width, 1.5 * height)
        }
        this.setState({ width, height }) ;
    }

    
    handleTurnInputWord=(input)=>{
        let diff = "Easy"
        if (input.points === 2) {
            diff = "Medium"
        } else if (input.points === 3) {
            diff = "Hard"
        }        
        this.setState({
            userState: UserStates.DRAWING_SETUP,
            word: input.word,
            clue: input.clue,
            difficulty: diff
        })

        trackEvent({
            category: "SinglePlayer", 
            action: `Word`,
            label: `${diff}/${input.word}/${input.clue}`
        })
    }

    handleTurnInput = (input) => {
        
        this.setState({
            userState: UserStates.DRAWING_SETUP,
            word: input.word,
            clue: input.clue,
            difficulty: "Custom",
        })

        trackEvent({
            category: "SinglePlayer", 
            action: "Word",
            label: `custom/${input.word}/${input.clue}`
        })
    }

    handleSendStroke = (stroke) =>{
        this.setState({
            drawingStrokes: [...this.state.drawingStrokes, stroke]
        })
    }

    handleCategoryVote = (category) => {
        this.setState({
            gameCategory: category
        })
        this.doneCategory()
        
        trackEvent({
            category: "SinglePlayer", 
            action: "CategorySelected",
            label: category
        })
        
        this.setState({
            hardWord : this.generateRandomWord("Hard", category),
            mediumWord : this.generateRandomWord("Medium", category),
            easyWord : this.generateRandomWord("Easy", category),

        })
    }

    handleBrushOptions = (open) => {
        this.setState({ bushOptionsOpen: open })
    }

    handleBrushSetupOptions = (open) => {
        this.setState({ brushSetupOpen: open })
    }

    handleGiveUpTurn = () => {
        console.log("Give up")
    }

    handleBrushChange = (brush) => {
        this.setState({
            active_brush: brush
        })
    }

    handleColorChange = (color) => {
        this.setState({
            active_color: color
        })
    }
    handleBackgroundColorChange = (color) => {
        this.setState({
            active_background_color: color
        })
    }

    countWords = (words) => {
        words = words.replace(/(^\s*)|(\s*$)/gi, "");
        words = words.replace(/[ ]{2,}/gi, " ");
        words = words.replace(/\n /, "\n");
        return words.split(' ').length;
    }

    drawerDrawing = () => {
        const { word, clue, active_brush, active_color, active_background_color } = this.state
        const { drawingStrokes } = this.state
        const num_words = this.countWords(word)
        const clue_wording = clue ? "Clue:" : "No clue"
        return (
            <div>
                <Timer seconds={119} onTimeout={this.drawingComplete} />
                {num_words === 1 ?
                    <WordClueSmall> Word: {word} </WordClueSmall>
                    :
                    <WordClueSmall> Phrase: {word} </WordClueSmall>
                }
                {num_words === 1 ?
                    <WordClueSmall>{clue_wording} {clue} (1 word)</WordClueSmall>
                    : <WordClueSmall>{clue_wording} {clue} ({num_words} words)</WordClueSmall>}

                {clue === "Dog Breed" &&
                        <CategoryImg src={ImageUtils.getImageForWord(word)} />}
                <BrushOptions active_brush={active_brush} active_color={active_color}
                    handleBrushChange={this.handleBrushChange}
                    handleColorChange={this.handleColorChange}
                    done={this.drawingComplete}
                    bushOptionsOpen={this.state.bushOptionsOpen}
                    handleBrushOptions={this.handleBrushOptions}
                    active_background_color={this.state.active_background_color}
                    onePlayer={true} />
            
                <Canvas
                    strokes={drawingStrokes}
                    onStroke={this.handleSendStroke}
                    active_background_color={active_background_color}
                    active_color={active_color}
                    active_brush={active_brush}
                    width={this.state.width}
                    height={this.state.height}
                    handleBrushOptions={this.handleBrushOptions}
                    />
            </div>
        )
    }

    drawerDrawingSetup = () => {
        const { active_brush, active_color, active_background_color } = this.state
        return (
            <div>
                <BrushSetup active_brush={active_brush} active_color={active_color} active_background_color={active_background_color}
                    handleBrushChange={this.handleBrushChange}
                    handleColorChange={this.handleColorChange}
                    handleBackgroundColorChange={this.handleBackgroundColorChange}
                    done={this.drawingSetupComplete}
                    onePlayer={true} />
                        
            </div>
        )
    }

    generateRandomWord = (difficulty, category) => {
        if (category === "Random") {
            const numCategories = wordList.Categories.length
            const index = Math.floor(Math.random() * numCategories)
            category = wordList.Categories[index].Name
        }

        const words = wordList.Categories.find(cc=>cc.Name === category).Words
        const word_index = Math.floor(Math.random() * words[difficulty].length)
        const word = words[difficulty][word_index]

        return {
            word: word,
            clue: category
        }
    }

    drawerWordInput=()=>{
        const category = this.state.gameCategory
        return (
            <div>
                <TurnInput 
                    onSubmit={this.handleTurnInput} 
                    width={this.state.width}
                    height={this.state.height}
                    hardWord={this.state.hardWord} mediumWord={this.state.mediumWord} easyWord={this.state.easyWord}
                    onWordSelcted={this.handleTurnInputWord}
                    customWordPoints = {Math.floor(Math.random() * 3) + 1}
                    category={category} />
            </div>

        )
        
    }

    introductionTimeOut = () =>{
        this.setState({
            userState: UserStates.CATEGORY
        })
    }

    doneCategory = () => {
        this.setState({
            userState: UserStates.TURN_INPUT
        })

    }

    onShowShareDialog = (show) => {
        this.setState({
            showShareDialog: show
        })

    }

    replayComplete = () => {
        this.setState({
            replayComplete: true,
        })

        setTimeout(()=>{
            if (this.shareWrapper){
                this.shareWrapper.scrollIntoView({behavior:'smooth'})
            }
        }, 1000)
    }

    drawingSetupComplete = () => {
        this.setState({
            userState: UserStates.DRAWING,
        })
    }

    drawingComplete = async () => {
        this.setState({
            userState: UserStates.DONE
        })

        trackEvent({
            category: "SinglePlayer", 
            action: "DrawingComplete",
        })

        // Send the data to the server
        const apiData = await axios.post("/api/replay", {
            strokes: strokeCompress.compress(this.state.drawingStrokes),
            word: this.state.word,
            userName: "",
            difficulty: this.state.difficulty,
            clue: this.state.clue,
            width: this.state.width,
            height: this.state.height,
            backgroundColor: this.state.active_background_color,
        })
        let currentUrl = window.location.href.split('?')[0]
        
        if (currentUrl.endsWith("/")){
            currentUrl = currentUrl.slice(0, -1); 
        }

        this.setState({
            shareLink: currentUrl + apiData.data.shareUrl
        })
    }

    introduction = () => {
        this.closeTimer = setTimeout(() => {
            this.introductionTimeOut()
        }, 4000);
        return (
            <div>
                <Steps>
                    <Step1> Draw a word </Step1>
                    <Step2> Share with friends </Step2>
                    <Step3> See what they guess! </Step3>

                </Steps>
                <Anim>
                    <Lottie animation={animationData.default} loop={true} />
                </Anim>
            
            </div>

        )

    }

    categoryVoting = () => {       
        return (
            <div>
                {/* <Timer seconds={29} onTimeout={this.doneCategory} /> */}
                <CategoryVote>
                    Choose a category:
                </CategoryVote>
                {categories.map((name, i) => {
                    
                    let backgroundColor = "rgb(200,200,200)"
                    if (name === "Random") backgroundColor = "#2958FF"
               
                    return (
                    <Button variant="contained" style={{ backgroundColor, color: "white", height: "40px", margin: "5px" }} key={i}
                        onClick={() => this.handleCategoryVote(name)}>
                        {name}
                    </Button>
                    )
                })}
            </div>
        )
    }

    done = () => {
        const { clue, word, drawingStrokes, difficulty} = this.state
        let diff_col = "#6bc558"
        if (difficulty === "Medium"){
            diff_col ="#ffc629"
        }
        else if (difficulty === "Hard"){
            diff_col = "#e7362e"
        }
        const num_words = this.countWords(word)
        const clue_wording = clue ? "Clue:" : "No clue"

        const link = this.state.shareLink
        return (
            <div>
                <CategoryVote>
                    Guess the Answer
                </CategoryVote>
                {/* {this.state.replayComplete &&
                    <Replay color={Styling.Gradients.Green}>
                        {word}
                    </Replay>
                } */}

                <WatchingGrid>
                    <div>
                        {num_words === 1 ? <React.Fragment> <WordClueSmall>{`${clue_wording} ${clue}`}</WordClueSmall>
                            <WordClueSmall>(1 word)</WordClueSmall> </React.Fragment> :
                            <React.Fragment><WordClueSmall>{`${clue_wording} ${clue}`}</WordClueSmall>
                                <WordClueSmall>{`(${num_words} words)`}</WordClueSmall></React.Fragment>
                        }
                    </div>
                    <Difficulty color={diff_col}> Difficulty: {difficulty} </Difficulty>
                    <div>
                    </div>
                </WatchingGrid>
                <ReplaySpace>
                </ReplaySpace>
                <CanvasPlayer strokes={drawingStrokes} width={this.state.width} height={this.state.height} scale={1.0}
                    onComplete={this.replayComplete} speed={0.5} active_background_color={this.state.active_background_color}  />
                
                {this.state.replayComplete &&
                <div ref={el => this.shareWrapper = el} >
                    <Share
                        showShare={this.onShowShareDialog}
                        shareUrl={link} />
                </div>
                }
               
                
            </div>

        )

    }




    render(){
        return (
            <Wrapper >
                {this.state.userState === UserStates.INTRODUCTION && this.introduction() }
                {this.state.userState === UserStates.CATEGORY && this.categoryVoting()}
                {this.state.userState === UserStates.TURN_INPUT && this.drawerWordInput()}
                {this.state.userState === UserStates.DRAWING_SETUP && this.drawerDrawingSetup()}
                {this.state.userState === UserStates.DRAWING && this.drawerDrawing()}
                {this.state.userState === UserStates.DONE && this.done()}
            </Wrapper>

        )
    }
}

    

export default OnePlayer

const Wrapper = styled.div`
    position: relative;
`
const Anim = styled.div`
  margin: 120px auto;
  width: 100%;
`
const CategoryImg = styled.img`
    width: 200px;
    margin: auto;
    padding: 5px;
`
const Animation1 = keyframes`
 0% {  opacity: 0; transform: scale(0.5) }
 16% { opacity: 0; transform: scale(0.5) }
 61% { opacity: 1;  transform: scale(1.2) }
 100% {  transform: scale(1.0)}
`
const Animation2 = keyframes`
 0% {  opacity: 0; transform: scale(0.5) }
 52% { opacity: 0; transform: scale(0.5) }
 75% { opacity: 1;  transform: scale(1.2) }
 100% {  transform: scale(1.0)}
`
const AnimationStep3 = keyframes`
 0% {  opacity: 0; transform: scale(0.5) }
 66% { opacity: 0; transform: scale(0.5) }
 83% { opacity: 1;  transform: scale(1.2) }
 100% {  transform: scale(1.0)}
`
const Step1 = styled.div`
    transform: scale(1.0);
    animation-name: ${ Animation1};
    animation-duration: 1.2s;
    margin: 20px 0px;
`
const Step2 = styled.div`
    transform: scale(1.0);
    animation-name: ${ Animation2};
    animation-duration: 2.1s;
    margin: 20px 0px;
`
const Step3 = styled.div`
    transform: scale(1.0);
    animation-name: ${ AnimationStep3};
    animation-duration: 3.0s;
    margin: 20px 0px;
`
const ReplaySpace = styled.div`
  min-height:50px;
`
const Steps = styled.div`
    margin-top: 50px;
    font-family: 'Baloo Chettan 2', cursive;
    color: #2958FF;
    font-size: 25px;
`
const WordClueSmall = styled.div`
    margin: 0px;
    border-radius: 10px;
    font-size: 18px;
    color: #a9a9a9;
    font-family: 'Baloo Chettan 2', cursive;
`
const Difficulty = styled.div`
    margin: 0px;
    border-radius: 10px;
    font-size: 18px;
    color: ${props => props.color};
    font-family: 'Baloo Chettan 2', cursive;
`
const WatchingGrid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 10px 0px;
`
const CategoryVote = styled.div`
    margin-bottom: 20px;
    font-size: 25px;
    color: #2958FF;
    font-family: 'Baloo Chettan 2', cursive;
`
