import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {Link, useHistory} from 'react-router-dom'

// import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Select from '@material-ui/core/Select';
import { keyframes } from 'styled-components'
import { API } from "aws-amplify";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Toolbar } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const deleteWord=(categories, catIndex, wordIndex)=>{
    return categories.map( (cc,ci)=>{
        if (ci===catIndex){
            return {
                ...cc, 
                Words : cc.Words.filter( (ww, wi)=> wi !== wordIndex )
                }
        } else return cc
    })
}

const addWord=(categories, catIndex)=>{
    return categories.map( (cc, ci)=>{
        if (ci === catIndex){
            return {
                ...cc, 
                Words : [...cc.Words, {
                    Word: "",
                    Difficulty: "Easy"
                }]
            }
        } else return cc
    })
}

const addCategory=(categories)=>{
    return [{
        Name: "",
        Words: [
            {
                Word: "",
                Difficulty: "Easy",
            },{
                Word: "",
                Difficulty: "Medium",
            },{
                Word: "",
                Difficulty: "Hard",
            }
    ]}, ...categories]
}

const updateWord=(categories, catIndex, wordIndex, newWord)=>{
    return categories.map( (cc, ci)=>{
        if (ci === catIndex){
            return {
                ...cc, 
                Words : cc.Words.map( (ww, wi)=>{
                if (wi === wordIndex){
                    return newWord
                } else return ww
            })}
        } else return cc
    })
}

const updateCategory=(categories, catIndex, newCategory)=>{
    return categories.map( (cc, ci)=>{
        if (ci === catIndex){
            return {
                ...cc, 
                Name : newCategory
            }
        } else return cc
    })
}

const cleanSettings=(settings)=>{
    let categories = settings.Categories

    categories = categories.map(cc=>({
        ...cc,
        Name: cc.Name.trim(),
        Words: cc.Words.map(ww=>({
            ...ww, 
            Word: ww.Word.trim()}
        )).filter(ww=>ww.Word.length > 0)    
    }))
    
    categories = categories.filter( cc=> cc.Words.length > 0 )
    
    // For each category, count the number of easy, med, hard
    for(let ii=0; ii<categories.length; ++ii){
        const cc = categories[ii]
        let easyCount = cc.Words.filter(ww=>ww.Difficulty==="Easy").length
        let medCount = cc.Words.filter(ww=>ww.Difficulty==="Medium").length
        let hardCount = cc.Words.filter(ww=>ww.Difficulty==="Hard").length
        console.log(easyCount, medCount, hardCount)
        if (easyCount < 1 || medCount < 1 || hardCount < 1){
            return {
                    error: "Error, each category must have at least one word per difficulty"
            }
        }
    }

    for(let ii=0; ii<categories.length; ++ii){
        if (categories[ii].Name === "Random"){
            return {
                error: "Error, category name can not be 'Random'"
            }
        }

        if (categories[ii].Name.length === 0){
            return {
                error: "Error, categorys must have a title"
            }
        }
    }

    const DisplayName = settings.DisplayName.trim()
    const RoomName = settings.RoomName.toLowerCase().trim()
    const Password = settings.Password.trim()

    let cleanedSettings = {
        ...settings, 
        DisplayName,
        RoomName,
        Password,
        Categories: categories
    }

    return {cleanedSettings, error:null}
}

const Settings =(props)=>{

    const [settings,setSettings] = useState({
        DisplayName:"",
        RoomName:"",
        Categories: [],
        Password: "",
        AutoCheckGuess: false,
    })

    const history = useHistory()

    const [tabIndex, setTabIndex] = React.useState(0);

    const TAB_LABELS = ["Game Settings", "Custom Words" ] //, "Subscription"]

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [isLoading, setLoading] = useState(false)
    const [isDirty, setDirty] = useState(false)
    const [errorString, setErrorString] = useState('')

    const updateSettings=(newSettings)=>{
        setDirty(true)
        setSettings(newSettings)
    }

    React.useEffect(()=>{
        refreshSettings()    
      }, [props.open]) //dep on open so we refresh - another option would be to mount this component new each time...
    
    const refreshSettings =()=>{
        setLoading(true)
        try{
            API.get("api", "/settings").then( (result)=>{
                console.log(result)
                setSettings( result.Settings)
                setLoading(false)
                setErrorString("")
                setDirty(false)
            } )
        } catch (error) {
            setLoading(false)
            setErrorString("Error loading Settings")
            setDirty(false)
        }

    }
    const uploadSettings = async()=>{

        // Check that we are admin for the room
        try{
            let result = await API.get("api", "/rooms/" + encodeURI(settings.RoomName) )
            console.log(result)
            if (result.isPrivate === true && result.isAdmin === false){
                console.log("Room name taken")
                setLoading(false)
                setErrorString("Error, room name taken")
                return
            }
        } catch (error) {
            setLoading(false)
            setErrorString("Error, room name taken")
            return
        }

        let {cleanedSettings, error} = cleanSettings(settings)
        
        if (error){
            setLoading(false)
            setErrorString(error)
        } else {
            try{
                setSettings(cleanedSettings)
                setLoading(true)
                API.put("api", `/settings`, {
                        body: {
                            ...cleanedSettings
                        }
                    }).then( async (result)=>{
                        console.log(result)
                        await props.onNewSettings()
                        setLoading(false)
                        setErrorString("")
                        setDirty(false)
                    })
            } catch (error) {
                setLoading(false)
                setErrorString("Error uploading settings")
            }
        }
    }

    

    const diffToVal=(diff)=>{
        return diff === "Easy" ? 0 : diff === "Medium" ? 1 : 2
    }

    const valToDiff=(val)=>{
        // It seems like Val is some other object, not Number, maybe a float or something so 
        //  comparing to an int with === is false. Using == here works
        val = Number(val)
        return val === 0 ? "Easy" : val === 1 ? "Medium" : "Hard"
    }

    const Difficulty=(props)=>(
        <Select
        MenuProps={{ menuStyle: { fontSize: '22pt', color:'#2958ff'} }}
          native
          value={diffToVal(props.difficulty)}
          onChange={(event)=>{
              props.onChange(valToDiff(event.target.value))
            }}
        >
          <option value={0}>Easy</option>
          <option value={1}>Medium</option>
          <option value={2}>Hard</option>
        </Select>
    )

    return (
        <Wrapper>
            <AppBar color="secondary" position="static">
            <Toolbar>
            {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                <IconButton onClick={()=>history.goBack()}>
                    <ArrowBackIosIcon style={{ color: "white" }}/>
                </IconButton>
            {/* </Link> */}
            <Tabs value={tabIndex} onChange={handleTabChange}>
                { TAB_LABELS.map(ll=>(<Tab key={ll} label={ll} />)) }
            </Tabs>
            </Toolbar>
            </AppBar>
            {(isDirty || isLoading || errorString.length > 0) &&
                <ActionsWrapper>
                    {errorString.length > 0 &&
                        <ErrorDiv>
                            {errorString}
                        </ErrorDiv>
                    }
                    {isLoading &&
                        <LoadingWrapper>
                            <CircularProgress size={20} thickness={10} />
                        </LoadingWrapper>
                    }
                    {isDirty &&
                        <React.Fragment>
                            <Button style={{color:"rgb(100,100,100)"}}onClick={refreshSettings} >Cancel</Button>
                    <Button style={{ color: "#2958ff", background: "white", border:"#2958ff"}} 
                        onClick={uploadSettings} variant="outlined">Save</Button>
                        </React.Fragment>
                    }
                </ActionsWrapper>
            }       

            { tabIndex === 0 && (
                <TabWrapper>
                    <StepHeader> Display Name </StepHeader>
                    <StepSubHeader> Your name that others will see. </StepSubHeader>
                <TextField
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    name='display name'
                    onChange={e => {
                        updateSettings({
                        ...settings,
                        DisplayName: e.target.value})
                    }}
                    value={settings.DisplayName}
                    style={{ padding: "10px 0px" }}
                    label="Display Name" />
                <br />
                <StepHeader> Room Name </StepHeader>
                <StepSubHeader> Your unique room name that others will join. </StepSubHeader>
                <TextField
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    name='room name'
                    style={{ padding: "10px 0px" }}
                    onChange={e => {
                        updateSettings({
                        ...settings,
                        RoomName: e.target.value})
                    }}
                    value={settings.RoomName}
                    label={"Room Name"} />
                <StepHeader> Magic Word </StepHeader>
                <StepSubHeader> Make your room private by requiring a magic word to enter. 
                    Leave blank for a public room. </StepSubHeader>
                <TextField
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined"
                    name='password'
                    style={{ padding: "10px 0px" }}
                    onChange={e => {
                        updateSettings({
                        ...settings,
                        Password: e.target.value})
                    }}
                    value={settings.Password}
                    label={"Room Password"} />
                <StepHeader> Auto Check Guesses </StepHeader>
                <StepSubHeader> Allow the system to check the guesses instead of the drawer </StepSubHeader>
                <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Off</Grid>
                <Grid item>    
                    <Switch 
                        checked={settings.AutoCheckGuess} onChange={e=>{
                            updateSettings({
                                ...settings,
                                AutoCheckGuess: e.target.checked
                            })
                        }}
                    />
                </Grid>
                <Grid item>On</Grid>
                </Grid>
                </Typography>

                </TabWrapper>
            )}

            { tabIndex === 1 && 
                <div>
                    <AddCategoryBar>
                        <Button style={{ color: '#2958ff' }}
                            startIcon={<AddCircleOutlineIcon /> }
                            onClick={() => updateSettings({
                                ...settings,
                                Categories : addCategory(settings.Categories)
                            })}>
                        <div style={{ color: '#2958ff' }}> New Category </div>
                        </Button>
                    </AddCategoryBar>
            
                {settings.Categories.map( (category, catIndex)=>{
                    return (
                    <CategoryWrapper style={{ backgroundColor:'white'}} key={catIndex}>
                        <CategoryTitle >
                            <TextField value={category.Name}
                                    InputProps={{ style: { fontSize: '22pt', color:'#2958ff'} }}
                                label="Category Title"
                                onChange={(e) => updateSettings({
                                    ...settings,
                                    Categories : updateCategory(settings.Categories, catIndex, e.target.value)
                                })}
                            />
                        </CategoryTitle>
                        <TableContainer >
                            <Table style={{ backgroundColor:'white'}} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}}>Word</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Difficulty</TableCell>
                                        <TableCell size="small" padding="none"  ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {category.Words.map( (word, wordIndex)=>(
                                        <TableRow key={wordIndex} style={{backgroundColor : wordIndex%2===0? "#aaaaaa" : "#ffffff"}}>
                                                <TableCell style={{ backgroundColor:'white'}}>
                                                <TextField value={word.Word} 
                                                    onChange={(e) => updateSettings({
                                                        ...settings,
                                                        Categories : updateWord(settings.Categories, catIndex, wordIndex, {
                                                            ...word, 
                                                            Word: e.target.value
                                                        })
                                                    })}
                                                />
                                            </TableCell>
                                            <TableCell style={{ backgroundColor:'white'}}>
                                                <Difficulty word={word.Word} difficulty={word.Difficulty}
                                                    onChange={(diff)=>{
                                                        updateSettings( {
                                                            ...settings,
                                                            Categories : updateWord(settings.Categories, catIndex, wordIndex, {
                                                                ...word, 
                                                                Difficulty:diff
                                                            })
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ backgroundColor:'white'}} size="small" padding="none" >
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={() => updateSettings({
                                                        ...settings,
                                                        Categories : deleteWord(settings.Categories, catIndex, wordIndex)
                                                    })}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <AddWordBar>
                            <Button
                                    startIcon={<AddCircleOutlineIcon style={{ color: '#2958ff' } }/> }
                                onClick={() => updateSettings({
                                    ...settings,
                                    Categories : addWord(settings.Categories, catIndex)
                                })}>
                                    <div style={{ color: '#2958ff' }}> Add Word </div>
                            </Button>
                        </AddWordBar>
                    </CategoryWrapper>)
                })}
                </div>
            }

            {/* {(isDirty || isLoading || errorString.length > 0) &&
                <ActionsWrapper>
                    { errorString.length > 0 && 
                        <ErrorDiv>
                            {errorString}
                        </ErrorDiv>
                    }
                    {isLoading &&
                    <LoadingWrapper>
                        <CircularProgress size={20} thickness={10}/>
                    </LoadingWrapper>
                    }
                    {isDirty &&
                        <React.Fragment>
                            <Button onClick={refreshSettings} >Cancel</Button>
                            <Button onClick={uploadSettings} variant="outlined" color="secondary">Save</Button>
                        </React.Fragment>
                    }
                </ActionsWrapper>
            }        */}

        </Wrapper>
    )
}

const CategoryWrapper = styled.div`
    margin-bottom: 10px;
    background-color: #dddddd;
    padding: 10px;
`

const CategoryTitle = styled.div`
    text-align: left;
    margin-bottom: 25px;
    padding-left:15px;
`

const AddWordBar = styled.div`
`

const AddCategoryBar = styled.div`
`

const pulseAnimation = keyframes`
 0% { background-color: #2958ff; transform: scale(1, 0.5) }
 80% { background-color: #ffffff; transform: scale(1, 1.1) }
 100% { transform: scale(1, 1.0)}
`

const ActionsWrapper = styled.div`
    text-align: right;
    /* position: fixed; */
    bottom: 0px;
    right: 0px;
    padding: 10px;
    width: 100%;
    background-color: rgb(230,230,230);
    border-top: 2px solid #aaaaaa;
    animation-name: ${pulseAnimation};
    animation-duration: 0.5s;
`

const StepHeader = styled.div`
    font-size: 20pt;
    margin: 5px 0px;
    font-weight: bold;
    font-family: 'Baloo Chettan 2', cursive;
    color: rgb(100,100,100);
`

const StepSubHeader = styled.div`
    font-size: 10pt;
    color: rgb(150,150,150);
    /* color: #ffc629; */
    margin-bottom: 15px;
    /* font-weight: bold; */
`

const TabWrapper = styled.div`
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
`

const LoadingWrapper = styled.div`
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
`

const Wrapper = styled.div`
    font-size: 20px;
    background-color: #f8f8f8;
`

const ErrorDiv = styled.div`
    font-size: 16px;
    color: red;
    font-weight: 300;
    /* display: inline-block; */
    text-align: left;
    /* position:absolute; */
    left:0;
    padding-left:10px;
`

export default Settings