import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CanvasPlayer from './canvasPlayer';

import Lottie from "../Lottie";
import * as animationData from "../animations/turnWinner.json"


class TurnFinished extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
  
    focusGuess = () => {
        // setTimeout(() => {
        //     if (this.guessWrapper) {
        //         this.guessWrapper.scrollIntoView({ behavior: 'smooth' })
        //     }
        // }, 1000);
    }

    render(){
        const { winner, drawer, word, width, height, guesses } = this.props.turnFinishedData
        
        const drawingStrokes = this.props.strokes
        const scale = this.props.scale        
        return (
            <TurnWinner>
                <WinnerText> {winner} won! </WinnerText>
                <WinnerWords>
                    {drawer}'s {this.props.phrase} was {word}
                </WinnerWords>
                <Anim>
                    <Lottie animation={animationData.default} loop={false} />
                </Anim>

                <CanvasPlayer strokes={drawingStrokes} width={width} height={height} scale = {scale} onComplete={this.focusGuess}
                    active_background_color={this.props.active_background_color} />

                <Guesses ref={el=>this.guessWrapper=el}>
                {this.props.showGuesses(guesses)}
                </Guesses>
                <Button variant="contained" type="submit" style={{
                    height: "40px", marginTop: "10px",
                    position: "absolute", top: "0px", right: "10px",
                    background: "#ffc629", color: "white", fontWeight: 900, borderRadius: "10px"
                }}
                    onClick={this.props.next}
                >
                    Next
                </Button>
            </TurnWinner>
        )
    }
}

export default TurnFinished


const TurnWinner = styled.div`
  margin: auto auto;
  margin-bottom: 20px;
  width: 100%;
  border: solid #ffc629 2px;
  background-color: white;
  border-radius: 20px;
  top:0;
  color: black;
`
const Guesses = styled.div`
    margin: 10px;
`

const WinnerText = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin: 50px 0px;
    color: rgb(80,80,80);
    letter-spacing: 2px;
`

const Anim = styled.div`
  margin: 0 auto;
  position: absolute;
  /* left: 50%; */
  /* margin-left: -140px; */
  width: 100%;
  top: 0;
`

const WinnerWords = styled.div`
   margin: 40px;
`

