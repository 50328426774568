import React from 'react'
import styled from "styled-components"
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class About extends React.Component{
    constructor(props){
        super(props)
        this.state={
            feedback : {},
            submitted: false,
        }
    }

    render(){
        return ( 
            <Wrapper>
                <Overlay>
                <IconButton
                    aria-haspopup="true"
                    onClick={this.props.onClose}
                    style={{ position: "absolute", top: "0px", right: "10px" }} >
                    <CloseIcon />
                </IconButton>
                <SubmittedText>
                    <Title> Licenses: </Title>
                    <br/>
                    Animations by:
                    <br/>
                    Trophy: @Lucas Nemo / LottieFiles
                    <br/>
                    Panda: @Erez / LottieFiles
                    <br />
                    Celebration: @Nicolas Van Leekwijck / LottieFiles
                    <br />
                    Connecting: @Ankit Kumar / LottieFiles
                    <br />
                    Clouds: @Carlos E. Ochoa / LottieFiles
                    <br />
                    Add a contact:  @LottieFiles / LottieFiles
                    <br />
                    Close: @Sasha Kulinich / LottieFiles
                    <br />
                    Party Penguin: @Alex Bradt / LottieFiles
                    <br />
                    Color Line: @LottieFilez / LottieFiles
                </SubmittedText>
                </Overlay>
            </Wrapper>
        )
    }
}

export default About

const Wrapper = styled.div`
    /* position: relative; */
`

const Overlay = styled.div`
  margin: auto auto;
  position: absolute;
  top:100px;
  margin-bottom: 20px;
  width: 100%;
  border: solid #ffc629 2px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  top:0;
  color: black;
  z-index: 200;
`
const SubmittedText = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left:20px;
  margin-right:20px;

  font-size: 12px;
  text-align:left;

`

const Title = styled.div`
  margin: 5px 0px;
  font-size: 18px;
  text-align:center;
`
