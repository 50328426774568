import React from "react"
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import styled from "styled-components"
import Slide from '@material-ui/core/Slide';

function TransitionDown(props) {
    return <Slide {...props} direction="down" timeout={300}/>;
  }

class Notification extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            open: true,
            accepted: false
        }
    }

    handleClose=(e,reason)=>{
        if (reason !== 'clickaway'){
        }
    }

    handleReject =()=>{
        this.setState({
            open:false,
            accepted: false
        })
        
    }

    handleAccept =()=>{
        this.setState({
            open:false,
            accepted: true
        })
    }

    onComplete =()=>{
        console.log("Exited")
        if (this.state.accepted){
            this.props.onAccept(this.props.notification)  
        } else {
            this.props.onReject(this.props.notification)
        }
        this.setState({open:true})
    }

    render(){
        const {user, word} = this.props.notification
        const message = `${user} guessed ${word}`

        return (
            <Snackbar open={this.state.open} 
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal:'center',
                }}
                onExited={this.onComplete}
                TransitionComponent={TransitionDown}
                style={{minWidth: "350px"}}
                >
                    {this.props.confirmation ? 
                    <ConfirmWrapper>
                        <Message>Confirm correct guess:</Message>
                        <Guess> {word} </Guess>
                        <Actions>
                            <Button onClick={ ()=>this.props.onConfirmAccept(this.props.notification)}
                                style={{ margin: "5px 5px", color: "#ffc629", fontWeight: "bolder", background: "white", border: "solid white 2px" }} >Correct</Button>
                            <Button onClick={this.handleReject}
                                style={{ margin: "5px 5px", color: "white", fontWeight: "bolder", border: "solid white 2px" }} >Reject</Button>
                        </Actions>
                    </ConfirmWrapper>
                    :
                
                    <Wrapper>
                        <Message>{message}</Message>
                        <Actions>
                            <Button onClick={this.handleAccept}
                            style={{ margin: "5px 5px", color: "#ffc629", fontWeight: "bolder", background:"white",  border: "solid white 2px" }} >Accept</Button>
                            <Button onClick={this.handleReject}
                            style={{ margin: "5px 5px", color: "white", fontWeight: "bolder", border: "solid white 2px" }} >Reject</Button>
                        </Actions>
                    </Wrapper>
                    }
            </Snackbar>
        );
    }
}

const Message = styled.div`
`

const Guess = styled.div`
    font-weight: bolder;
    margin: 8px 0px;
    font-size: 30px;
`

const Actions = styled.div`
    margin-left: 'auto';
    padding-left: 16px;
`

const Wrapper = styled.div`
    background: linear-gradient(#2958FF, #6acfff);
    border-radius: 5px 5px 20px 20px;
    padding: 15px;
    width:100%;
    color: white;
    font-weight: bolder;
    margin-top: 50px;
`

const ConfirmWrapper = styled.div`
    /* background: linear-gradient(#ff0000, #f99500); */
    background: linear-gradient(#2958FF, #00a2ef);
    border-radius: 5px 5px 20px 20px;
    padding: 15px;
    width:100%;
    color: white;
    font-weight: bolder;
    margin-top: 50px;
`

export default Notification