import React, { Component } from 'react';
import styled from "styled-components"
import contrastColor from "./contrastColor"
const brushes = [
    5,
    25,
    45
]


class BrushPicker extends Component {
    constructor(props) {
    super(props);

    this.state={
    }
    }
    render() {
        const pos = this.props.center ? "none" : "right"
    return (
        <Wrapper pos={pos}>       
        {brushes.map( b => (
            <BrushClickWrapper key={b + "brush"} onClick={() => {
                this.props.handleBrushChange(b)
            }}>
                <BrushWrapper  >
                    <BrushSwatch brush={b} selected={b === this.props.active_brush} color={this.props.active_color} />
                </BrushWrapper >
            </BrushClickWrapper>
        ))}
    
        
    </Wrapper>
    );
    }
}

const Wrapper = styled.div`
  /* position: absolute; */
  /* top: 350px; */
  /* left: 0; */
  /* background-color:rgb(220,220,220); */
  z-index: 100;
  display: grid;
  grid-template-columns: 35px 55px 75px;
  /* border-radius: 5px; */
  /* margin: 5px; */
  float:${props => props.pos};
  width: 165px;
  margin:auto;
`; 

const BrushClickWrapper = styled.div`
  padding: auto auto;
  cursor: pointer;  
`;  

const BrushWrapper = styled.div`
  width: ${props => `${props.brush + 5}px`};
  padding: 15px 0;
  height:  ${props => `${props.brush + 5}px`};
`;  

const BrushSwatch = styled.div`
  width: ${props => `${props.brush + 5}px`};
  margin: auto 0px;
  height:  ${props => `${props.brush + 5}px`};
  background-color:  ${props => props.color};
  border-radius:  ${props => `${props.brush + 5}px`};
  border-color:  ${props => props.selected ? contrastColor(props.color) : props.color};
  border-width: ${props => props.selected ? '3px' : '3px' };
  border-style: solid;
`;


export default BrushPicker;