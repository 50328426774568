import React from 'react'
import styled from "styled-components"
import ScrollContainer from 'react-indiana-drag-scroll'
import GuessStates from "./GuessStates"

var Styling = require('./styling.json')

const getFocusIndex =(guesses)=>{
    if (guesses.length === 0) return -1
    let index = guesses.findIndex(gg=>gg.state === GuessStates.OPEN)
    if (index !== -1){
        return index
    } else {
        return guesses.length - 1
    }
}

const colorFromState = (state)=>{
    switch (state) {
        case GuessStates.OPEN: return Styling.Gradients.Grey
        case GuessStates.WAITING_FOR_APPROVAL: return Styling.Gradients.YellowTheme
        case GuessStates.DRAWER_DECISION: return Styling.Gradients.YellowTheme
        case GuessStates.REJECTED: return Styling.Gradients.RedError
        case GuessStates.ACCEPTED: return Styling.Gradients.Green
        case GuessStates.TIMEOUT: return Styling.Gradients.Pink
        default: return Styling.Gradients.Grey
    }
}

class GuessTimeline extends React.Component {
    constructor(props){
        super(props)
        this.guessRefs = props.guesses.map(gg=>React.createRef())
        this.state = {
            focusIndex : getFocusIndex(props.guesses)
        }
    }

    componentDidUpdate(prevProps){
        let numNewGuesses = this.props.guesses.length - this.guessRefs.length
        if (numNewGuesses){
            for(let i=0; i<numNewGuesses; ++i){
                this.guessRefs.push( React.createRef())
            }
        }

        let newFocusIndex = getFocusIndex(this.props.guesses)        
        if (newFocusIndex !== -1 && newFocusIndex !== this.state.focusIndex){
            this.setState({ focusIndex: newFocusIndex })

            // We need to wait for the next update
            window.requestAnimationFrame(()=>{
                let target = this.guessRefs[this.state.focusIndex].current
                if (target){
                    target.scrollIntoView({behavior: 'smooth'})
                } 
            })
        }
    }

    componentDidMount(){
    }

    renderGuessWord=(guess,index)=>{
        if (guess.state === GuessStates.OPEN) return ". . ."
        if (guess.state === GuessStates.TIMEOUT) return "TIMEOUT"
        if (index <= this.state.focusIndex) return guess.word
        return "____"
    }

    render(){
        let {guesses} = this.props                
        return (
            <Wrapper 
                vertical={false}
                horizontal={true}
                hideScrollbars={true}
                // ref={el=>this.scrollEl = el}
                >
            <ContentWrapper >
                {guesses.map( (guess, ii)=>(
                    <GuessWrapper color={colorFromState(guess.state)} key={ii} ref={this.guessRefs[ii]}>
                        <GuessContent>
                            <GuessUser>
                                {guess.user}
                            </GuessUser>
                            <GuessWord>
                                {this.renderGuessWord(guess,ii)}
                            </GuessWord>
                        </GuessContent>
                    </GuessWrapper>
                ))}
            </ContentWrapper>
        </Wrapper>

        )
    }
}

    

export default GuessTimeline

const Wrapper = styled(ScrollContainer)`
    position: relative;
    width: 100%;
    margin-bottom:10px;
    height: 51px;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
`

const GuessWrapper = styled.div`
    margin-left: 10px;
    padding: 3px 10px;
    border-radius: 20px;
    background: ${props=>props.color};
    color: white;
    font-family: 'Baloo Chettan 2', cursive;
    flex: 0 0 auto;
    border-radius: 5px;
`

const GuessContent = styled.div`
    display: flex;
    flex-direction: column;
`

const GuessUser = styled.div`
    font-size: 12px;
    font-weight: normal;
`

const GuessWord = styled.div`
    font-size: 20px;
    font-weight: bolder;
`

