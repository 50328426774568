
function contrastColor(color) {
    if (color === "#101010" || 
        color === "#0c2654" || 
        color === "#76070f" || 

        color === "#125b2c" || 
        color === "#1e70b8" || 
        color === "#642e8d" || 

        color === "#7b4a18"){
        
        return "#ea2332"
    } else {
        return "#101010"
    }
}



export default contrastColor