import React, {useState, useRef} from 'react'
import styled from "styled-components"
import TextField from '@material-ui/core/TextField';
import ServerTimer from "./serverTimer"
import ImageUtils from "./ImageUtils"
import Button from '@material-ui/core/Button';

var Styling = require('./styling.json')

const TurnInput =(props)=>{

    const [word, setWord] = useState('')
    const [clue, setClue] = useState('')
    let [enterOwn, setEnterOwn] = useState(false)
    let [enterOwnWord, setEnterOwnWord] = useState(false)

    const clueRef = useRef(null)
    
    const submit=(e)=>{
        e.preventDefault()
        if (word) {
            const width = props.width
            const height = props.height
            const points = props.customWordPoints
            const custom = true
            props.onSubmit({ word, clue, width, height, points, custom })
        }
    }
    const hardWordImage = ImageUtils.getImageForWord(props.hardWord.word)
    const mediumWordImage = ImageUtils.getImageForWord(props.mediumWord.word)
    const easyWordImage = ImageUtils.getImageForWord(props.easyWord.word)

   
    return (
        <Wrapper>
            {props.time && <ServerTimer time={props.time} /> }
            {enterOwn === false ? 
            <React.Fragment>
                {/* <GameCategory> Game Category: <span style={{ color: '#2958FF'}}>{props.category}</span> </GameCategory> */}

                {props.time && <TurnText> Your Turn! </TurnText> }
                <Text> Choose a Phrase/Word </Text>
                <DiffContainer><Diff> Hard: </Diff></DiffContainer>
                <WordButton style={{background:Styling.Gradients.Green}}
                    onClick={() => props.onWordSelcted({ word: props.hardWord.word, clue: props.hardWord.clue, width: props.width, height: props.height, points: 3 })}  >
                    {props.hardWord.word} ({props.hardWord.clue}) +3
                    {props.hardWord.clue === "Dog Breed" && <CategoryImg src={hardWordImage} />}
                </WordButton>
                <Spacer />
                <DiffContainer><Diff> Medium: </Diff></DiffContainer>
                <WordButton style={{ background: Styling.Gradients.Yellow }}
                    onClick={() => props.onWordSelcted({ word: props.mediumWord.word, clue: props.mediumWord.clue, width: props.width, height: props.height, points: 2 })} >
                    {props.mediumWord.word} ({props.mediumWord.clue}) +2
                    {props.mediumWord.clue === "Dog Breed" && <CategoryImg src={mediumWordImage} />}
                </WordButton>
                <Spacer />
                <DiffContainer><Diff> Easy: </Diff></DiffContainer>

                <WordButton style={{ background: Styling.Gradients.Red }}
                    onClick={() => props.onWordSelcted({ word: props.easyWord.word, clue: props.easyWord.clue, width: props.width, height: props.height, points: 1 })} >
                    {props.easyWord.word} ({props.easyWord.clue}) +1
                    {props.easyWord.clue === "Dog Breed" && <CategoryImg src={easyWordImage} />}
                </WordButton>
            
                <OrText>Or </OrText> 
                <WordButton style={{ background: Styling.Gradients.Blue }}
                    onClick={() => {
                        setEnterOwn(true) }} >
                    Write Your Own + ?
                    </WordButton>
            </React.Fragment>
            : 
            <React.Fragment>
            { enterOwnWord === false ?
                <React.Fragment>
                    <Text> Word/Phrase: </Text>
                    <TextField
                        variant="outlined"
                        name='word'
                        inputProps={{
                            maxLength: 30,
                        }}
                        autoFocus
                        onChange={e => setWord(e.target.value)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                if (word) {
                                    setEnterOwnWord(true)
                                }
                            }
                            
                            // if (e.key === 'Enter'){
                            //     clueRef.current.focus()
                            // }
                        }}
                        value={word}
                        placeholder="ex. kitten" />
                        <br/>
                        <Button variant="contained" type="submit" color="primary" style={{ height: "40px", margin: "10px 0px", fontWeight: 'bolder' }}
                        onClick={() => {
                            if(word){
                            setEnterOwnWord(true)}} } >
                        Next
                    </Button>
                </React.Fragment>
                : 
                <React.Fragment>                    
                    <Text> Give the Players a Clue: </Text>
                    {/* <SubText>Enter a clue about your phrase</SubText> */}
                    <TextField
                        variant="outlined"
                        name='clue'
                        autoFocus
                        inputRef={clueRef}
                        inputProps={{
                            maxLength: 30,
                        }}
                        onChange={e => setClue(e.target.value)}
                        onKeyPress={e => e.key === 'Enter' ? submit(e) : null}
                        value={clue}
                        placeholder="ex. animal" />
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{ height: "40px", margin: "10px 0px", fontWeight: 'bolder' }}
                        onClick={(e)=>{
                            submit(e)
                            
                            }} >
                        Submit 
                    </Button>
                            {props.customWordPoints === 1?
                    <PointsText> This turn is worth {props.customWordPoints} point </PointsText>   :
                    <PointsText> This turn is worth {props.customWordPoints} points! </PointsText> }
            </React.Fragment>
            }
            </React.Fragment>
            }
        </Wrapper>
    )
}

export default TurnInput

const Wrapper = styled.div`
    text-align: center;
    font-family: "Nanum Gothic", sans-serif;
    user-select: none;
    font-size: 20px;
    margin-bottom:20px;
`
const CategoryImg = styled.img`
    width: 200px;
    margin: auto;
    padding: 5px;
`
const WordButton = styled.div`
    font-weight: bolder;
    width: 250px;
    padding: 8px 15px;
    border-radius: 20px;
    color:white;
    font-size: 18px;
    margin: 5px auto;
    cursor: pointer;
`

const GameCategory = styled.div`
  color: rgb(180,180,180);
  letter-spacing: 2px;
  font-size: 25px;
  margin-bottom: 20px;
  font-family: 'Baloo Chettan 2', cursive;
`
const Text = styled.div`
    margin: 5px;
    color: rgb(180,180,180);
    font-size: 22px;
    font-family: 'Baloo Chettan 2', cursive;
`

const PointsText = styled.div`
    margin: 5px;
    color: rgb(180,180,180);
    font-size: 18px;
    font-family: 'Baloo Chettan 2', cursive;
`

const OrText = styled.div`
    margin: 15px 0px;
    color: rgb(180,180,180);
    font-size: 22px;
    font-family: 'Baloo Chettan 2', cursive;
`

const Diff = styled.div`
    color: #b1b1b1;
    font-size: 15px;
    text-align: left;
    margin-left: 16px;
`
const DiffContainer = styled.div`
    margin: 0px auto; 
    margin-top: 10px;
    width: 250px;
`

const TurnText = styled.div`
    margin: 10px;
    /* color: #ffc629; */
    /* color: #2958FF; */
    color: rgb(138,138,138);
    font-size: 30px;
    font-family: 'Baloo Chettan 2', cursive;
`

const Spacer = styled.div`
    margin: 25px;
`
