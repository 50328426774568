import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class Feedback extends React.Component{
    constructor(props){
        super(props)
        this.state={
            feedback : {},
            submitted: false,
        }
    }

    sendFeedback = (e) => {
        e.preventDefault()
        if (this.state.feedback ) {
            console.log("Submitting feedback", this.state.feedback)
            this.props.sendFeedback(this.state.feedback)
            this.setState({submitted: true})
            this.closeTimer = setTimeout(() => {
                this.props.onShowFeedback(false)
            }, 2000);
        }
    }

    componentWillUnmount(){
        if (this.closeTimer){
            clearTimeout(this.closeTimer)
            this.closeTimer = null
        }
    }

    render(){
        const feedback = this.state.feedback

        return ( 
            <React.Fragment>
            {this.state.submitted ? 
                <Submitted>
                    <SubmittedText> Message sent! </SubmittedText>
                    <IconButton
                        aria-haspopup="true"
                        onClick={() => { this.props.onShowFeedback(false) }}
                        style={{ position: "absolute", top: "0px", right: "10px" }} >
                        <CloseIcon />
                    </IconButton>
                </Submitted>
                 :
                <Overlay>
                    <form onSubmit={this.sendFeedback}>
                        <div style={{ marginTop: '50px' }} >
                            Questions or comments?
                    </div>
                        <div style={{ marginBottom: '20px', fontSize: '15px' }} >
                            We'd love to hear from you!
                    </div>
                        <TextareaAutosize
                            variant="outlined"
                            name='message'
                            rows="5"
                            cols="23"
                            autoFocus
                            onChange={(e) => this.setState({ feedback: { ...feedback, message: (e.target.value) } })}
                            placeholder="message" />
                        
                        <div style={{ margin: '20px' }} />
                        <TextField
                            variant="outlined"
                            name='name'
                            onChange={(e) => this.setState({ feedback: { ...feedback, name: (e.target.value) } })}
                            placeholder="name (optional)" />
                        <div style={{ margin: '20px' }} />
                        <TextField
                            variant="outlined"
                            name='email'
                            onChange={(e) => this.setState({ feedback: { ...feedback, email: (e.target.value) } })}
                            placeholder="email (optional)" />
                        
                        <br/>
                        <Button variant="contained" type="submit" color="primary"
                            style={{ backgroundColor: "#e7362e", fontWeight: "bolder", height: "40px", margin: "10px auto", borderRadius: "20px" }} >
                            Send
                        </Button>
                    </form>
                    <IconButton
                        aria-haspopup="true"
                        onClick={() => { this.props.onShowFeedback(false) }}
                        style={{ position: "absolute", top:"0px", right:"10px" }} >
                        <CloseIcon />
                    </IconButton>
                </Overlay>
            }
        </React.Fragment>
    )
    }
}

export default Feedback


const Overlay = styled.div`
  margin: auto auto;
  position: absolute;
  top:100px;
  margin-bottom: 20px;
  width: 100%;
  border: solid #ffc629 2px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  top:0;
  color: black;
  z-index: 200;
`

const Submitted = styled.div`
  margin: auto auto;
  position: absolute;
  top:100px;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  top:0;
  color: black;
  background: #ececec;
  z-index: 200;
`

const SubmittedText = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
`
