import React from "react"
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import styled from "styled-components"
import Slide from '@material-ui/core/Slide';

function TransitionDown(props) {
    return <Slide {...props} direction="down" timeout={300} />;
}

const messages={
    endGame:{
        main: "End Game?",
        accept: "End Game",
        reject: "Keep Playing"
    },
    giveUp: {
        main: "Give up turn?",
        accept: "Give Up",
        reject: "Keep Drawing"
    },
    startGame: {
        main: "Is Everyone Ready to Play?",
        accept: "Ready!",
        reject: "Not Ready"
    }
}

class ConfirmGiveUp extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            open: true,
            accepted: false
        }
    }

    handleClose = (e, reason) => {
        if (reason !== 'clickaway') {
        }
    }

    handleReject = () => {
        this.setState({
            open: false,
            accepted: false
        })

    }

    onComplete = () => {
        console.log("Exited")
        if (this.state.accepted) {

        } else {
            this.props.onReject()
        }
        this.setState({ open: true })
    }

    render() {
        return (
            <Snackbar open={this.state.open}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onExited={this.onComplete}
                TransitionComponent={TransitionDown}
                style={{ minWidth: "350px" }}
            >
                <ConfirmWrapper>
                    <Message>{messages[this.props.type].main}</Message>
                    <Actions>
                        <Button onClick={() => this.props.onAccept()}
                            style={{ margin: "5px 5px", color: "#ffc629", fontWeight: "bolder", background: "white", border: "solid white 2px" }} >{messages[this.props.type].accept}</Button>
                        <Button onClick={this.handleReject}
                            style={{ margin: "5px 5px", color: "white", fontWeight: "bolder", border: "solid white 2px" }} >{messages[this.props.type].reject}</Button>
                    </Actions>
                </ConfirmWrapper>
            </Snackbar>
        );
    }
}

const Message = styled.div`
`

const Actions = styled.div`
    margin-left: 'auto';
    padding-left: 16px;
`

const ConfirmWrapper = styled.div`
    /* background: linear-gradient(#ff0000, #f99500); */
    background: linear-gradient( rgb(180,180,180) , rgb(206,206,206));
    border-radius: 5px 5px 20px 20px;
    border: rgb(220,220,220) 2px solid;
    padding: 15px;
    width:100%;
    color: white;
    font-weight: bolder;
    margin-top: 45px;
`

export default ConfirmGiveUp