import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import styled from "styled-components"

import { withRouter } from "react-router";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';

import {useHistory} from 'react-router-dom'


const Subscribe =(props)=>{

    const handleNext = () => {
        
    };

    const drawpolyProForm = () => {
        return (
            <React.Fragment>
                <StepHeader> <span style={{ color: "#ffc629" }}> DRAWPOLY </span> <span style={{ color: "#2958FF"}}> PRO</span> </StepHeader>
                <List style={{padding:"0px, 100px"}}>
                    <ListItem> 
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Private room" secondary="Create a private room with a magic word to enter" /> 
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Custom Words" secondary="Add your own custom categories and words " />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon> 
                        <ListItemText primary="Admin Controls" secondary="Only you can start and end the game" /> 
                    </ListItem>
                </List>
                <div style={{margin:"10px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MonthlyWrapper>
                                <div style={{ padding: "18px" }}/>
                            <StepDesc >  MONTHLY </StepDesc>
                            <StepHeader style={{ fontSize: "25px", color: "rgb(150,150,150)" }}>
                                $4.99 USD
                            </StepHeader>
                            <StepDesc > PER MONTH </StepDesc>
                            <div style={{padding: "10px"}} />
                                <a style={{ textDecoration: "none" }}
                                    href="https://buy.stripe.com/3csbKG1M85La16U7st" rel="noopener noreferrer" target="_blank">
                                    <Button variant="contained" type="submit"
                                        style={{ fontWeight: "bold", color: "white" }}>
                                        Continue
                                    </Button>
                                </a>
                        </MonthlyWrapper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            <div style={{ fontSize: "15px", background: "#ffc629", color:"white", padding:"8px" }}> SAVE $9.89 </div>
                        <YearlyWrapper>
                            <StepDesc >  ANNUAL </StepDesc>
                            <StepHeader style={{ fontSize: "25px", color: "rgb(150,150,150)" }}>
                                $49.99 USD
                            </StepHeader>
                            <StepDesc > PER YEAR </StepDesc>
                            <div style={{ padding: "10px" }} />
                                <a style={{textDecoration: "none"}} 
                                href="https://buy.stripe.com/14k4ie9eA4H63f26oo" rel="noopener noreferrer" target="_blank">
                                    <Button variant="contained" type="submit" color="primary"
                                            style={{ fontWeight: "bold" }}>
                                        Continue
                                    </Button>
                                </a>
                        </YearlyWrapper>
                    </Grid>
                </Grid>
            </div>
            </React.Fragment >
        )
    }

    const history = useHistory()
    

    return (
        <Wrapper>
            <SectionWrapper>
                <Section>
                    {drawpolyProForm()}
                </Section>
              
            </SectionWrapper>
            
        </Wrapper>
    )
}

const MonthlyWrapper = styled.div`
    border: solid 3px rgb(220,220,220);
    padding: 30px 10px;
`

const YearlyWrapper = styled.div`
    border: solid 3px #ffc629;
    padding: 30px 10px;
`

const LoadingWrapper = styled.div`
    display: inline-block;
    margin-right: 20px;
`

const TofS = styled.div`
    user-select:none;
    margin: 20px 5px;
    margin-bottom: 0px;
    font-size: 12px;
    color:rgb(180,180,180);
`

const Wrapper = styled.div`
    text-align: center;
    font-size: 20px;
    /* font-family: 'Baloo Chettan 2', cursive; */
    background-color: #f8f8f8;
`

const ErrorDiv = styled.div`
    color: red;
    font-size: 16px;
    padding: 0px 15px;
`

const StepHeader = styled.div`
    font-size: 20pt;
    margin: 20px 0px;
    font-weight: bold;
    font-family: 'Baloo Chettan 2', cursive;
    color: rgb(100,100,100);
`

const StepSubHeader = styled.div`
    font-size: 12pt;
    color: #ffc629;
    margin-bottom: 20px;
    /* font-weight: bold; */
`
const StepDesc = styled.div`
    font-size: 13pt;
    margin: 0px 40px;
    color: rgb(150,150,150);
`

const OR = styled.div`
    font-size: 26;
    margin-bottom: 40px;
    margin-top: 40px;
    color: rgb(180,180,180);
`

const Section = styled.div`
    margin-top: 20px;
    padding: 5px;    
`

const SectionWrapper = styled.div`
    background-color: white;
    /* padding: 20px 15%; */
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    
`

export default withRouter(Subscribe)