import React, { Component } from 'react';
import styled from "styled-components"
import ColorPicker from './colorPicker';
import BrushPicker from './brushPicker';
import { keyframes } from 'styled-components'

const SetupStates = {
    BACKGROUND: 'BACKGROUND',
    COLOR: 'COLOR',
    SIZE: "SIZE",
}

class brushSetup extends Component {
  constructor(props) {
    super(props);

    this.state={
        setupState: SetupStates.BACKGROUND
    }
  }

doneBackground = (color) =>{
    this.setState({
        setupState: SetupStates.COLOR
    })
    this.props.handleBackgroundColorChange(color)
}
doneColor = (color) =>{
    this.props.handleColorChange(color)
    this.setState({
        setupState: SetupStates.SIZE
    })
}
doneSize = (size) =>{
    this.props.handleBrushChange(size)
    this.props.done()
}

background = () => {
    return (
        <React.Fragment>
            <Title> Background Color: </Title>
             <ColorPicker
                active_color={null}
                handleColorChange={this.doneBackground}
                backgroundSwatches={true} />
        </React.Fragment>
    )
}
color = () => {
    return (
        <React.Fragment>
            <Title> Brush Color: </Title>
             <ColorPicker
                active_color={null}
                handleColorChange={this.doneColor} />
        </React.Fragment>
    )
}
size = () => {
    return (
        <React.Fragment>
            <Title> Brush Size: </Title>
             <BrushPicker
                active_brush={null}
                active_color={this.props.active_color}
                handleBrushChange={this.doneSize}
                center={true} />
                
        </React.Fragment>
    )
}


render() {
    return (
        <Wrapper>
            {this.state.setupState === SetupStates.BACKGROUND && this.background()}
            {this.state.setupState === SetupStates.SIZE && this.size()}
            {this.state.setupState === SetupStates.COLOR && this.color()}
        </Wrapper>
    );
  }
}


const Animation1 = keyframes`
 0% {  transform: scale(0.9) }
 61% {  transform: scale(1.1) }
 100% {  transform: scale(1.0)}
`

const Wrapper = styled.div`
  text-align: center;
  z-index: 100;
  background: linear-gradient(#eaeaea, #ececec);
  border-radius: 20px;
  border: solid #ffc629 2px;
  position: relative;
  font-size: 25px;
  font-family: 'Baloo Chettan 2', cursive;
  color: #2958FF;
  height: 420px;
  padding: 40px 0px;
`; 

const Title = styled.div`
  padding-bottom: 50px;
  transform: scale(1.0);
  animation-name: ${ Animation1};
  animation-duration: 0.4s;
`; 

export default brushSetup;