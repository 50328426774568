
import ActionTypes from "./actionTypes"
import {createInitialState} from "./initialState"

const rootReducer=(state=createInitialState(), action) => {
    switch(action.type){
        case ActionTypes.SET_NAME:
            return {
                ...state, 
                name : action.payload
            }
        case ActionTypes.SET_ROOM:
            return {
                ...state, 
                room : action.payload
            }
        case ActionTypes.ADD_STROKE:
            return {
                ...state,
                drawingStrokes: [...state.drawingStrokes, action.payload]
            }
        case ActionTypes.CLEAR_STROKES:
            return{
                ...state,
                drawingStrokes: []
            }
        case ActionTypes.SET_MUTE:
            return {
                ...state,
                mute: action.payload
            }
        case ActionTypes.SET_CUSTOM_CATEGORIES:
            return {
                ...state,
                customCategories: action.payload
            }
        case ActionTypes.SET_ROOM_DATA:
            return {
                ...state,
                roomData: action.payload
            }
        case ActionTypes.SET_ADMIN_MAGIC_PASSWORD:
            return {
                ...state,
                adminMagicPassword: action.payload
            }
        case ActionTypes.SET_AUTO_CHECK_GUESS:
            return {
                ...state,
                autoCheckGuess: action.payload
            }
        case ActionTypes.SET_ACCOUNT_STATE:
            return {
                ...state,
                accountState: action.payload
            }
        default:
            if (action.type.startsWith("@@redux") === false){
                console.log("Error, unhandled action type", action.type)
            }
    }    
    return state
}

export default rootReducer