import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import styled from "styled-components"

class AdminWaitingRoom extends Component {

    constructor(props){
        super(props)
        this.state = {
            startGame: false
        }
    }

    componentDidMount() {
        this.setState({
            startGame: false
        })
    }

    handleStartGameClicked=()=>{
        this.setState({
            startGame: true
        })
    }

    handleReady = () => {
        this.setState({
            startGame: false
        })
        console.log("READY GAME")
    }



    render() {
        return (
            (this.state.startGame ? 
                <React.Fragment>
                <IconButton style={{position:"absolute", left: "-10px", top: "-10px"}} onClick={() => (this.setState({
                        startGame: false
                    }))}>
                    <ArrowBackIosIcon style={{ color: "rgb(200,200,200)" }} />
                </IconButton>
                <div style={{  paddingTop: "0px" }}>

                <CategoryVote style={{ paddingTop: "40px" }}>
                    Choose the categories for the game:
                </CategoryVote>
                    {this.props.default_categories.map((c, i) => {
                        if (this.props.admin_default_categories.includes(c.Name)) {
                            return (
                                <Button variant="contained" color="secondary" style={{ padding: "0px 16px", height: "40px", margin: "5px" }} key={i}
                                    onClick={() => this.props.handleAdminDefaultCategorySelection({ category: c.Name })}>
                                    {c.Name} <Checkbox
                                        checked={true}
                                        style={{ color: 'white' }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Button>
                            )
                        }
                        else {
                            return (
                                <Button variant="contained" style={{ backgroundColor: "rgb(200,200,200)", color: "white", padding: "0px 16px", height: "40px", margin: "5px" }} key={i}
                                    onClick={() => this.props.handleAdminDefaultCategorySelection({ category: c.Name })}>
                                    {c.Name}
                                    <Checkbox
                                        checked={false}
                                        style={{ color: 'rgb(220,220,220)' }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Button>
                            )
                        }
                    })
                    }
                    <CategoryVote style={{ marginTop: "40px" }}>
                        My Categories:
                    </CategoryVote>
                    {this.props.custom_categories.length > 0 ?
                    <div>
                    {this.props.custom_categories.map((c, i) => {
                        if (this.props.admin_custom_categories.includes(c.Name)) {
                            return (
                                <Button variant="contained" color="secondary" style={{ padding: "0px 16px", height: "40px", margin: "5px" }} key={i}
                                    onClick={() => this.props.handleAdminCustomCategorySelection({ category: c.Name })}>
                                    {c.Name} <Checkbox
                                        checked={true}
                                        style={{ color: 'white' }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Button>
                            )
                        }
                        else {
                            return (
                                <Button variant="contained" style={{ backgroundColor: "rgb(200,200,200)", color: "white", padding: "0px 16px", height: "40px", margin: "5px" }} key={i}
                                    onClick={() => this.props.handleAdminCustomCategorySelection({ category: c.Name })}>
                                    {c.Name}
                                    <Checkbox
                                        checked={false}
                                        style={{ color: 'rgb(220,220,220)' }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Button>
                            )
                        }
                    })
                    }</div>
                    :
                    <CustomCategoories>
                        Add custom words and categories in settings
                    </CustomCategoories>}
                <br/>
                    <Button variant="contained" onClick={() => {this.props.handleStartGameClicked()
                        // this.setState({
                        //     startGame: false
                        // })
                    }} color="primary" style={{ margin: "5px", fontSize: "22px", fontWeight: "bold" }}>
                    Play
                </Button>
            </div>
            </React.Fragment>
            :
            <div style={{ marginTop: "40px" }}>
                <Button variant="contained" onClick={this.handleStartGameClicked} color="primary" style={{ margin: "5px", fontSize: "22px", fontWeight: "bold" }}>
                    Start Game
                </Button>
                
            </div>
            )
        )
    }
}
export default AdminWaitingRoom;


const CategoryVote = styled.div`
    margin-bottom: 20px;
    color: rgb(170,170,170);
    font-family: 'Baloo Chettan 2', cursive;
`
const CustomCategoories = styled.div`
    color: rgb(180,180,180);
    font-size: 15px;
    font-family: 'Baloo Chettan 2', cursive;
`