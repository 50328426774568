const getImageForWord=(word)=>{

    let path = word.toLowerCase()
        .split(' ')
        .map((ss,ii) => ii === 0 ? ss : ss.charAt(0).toUpperCase() + ss.substring(1))
        .join('') + ".jpg"

    return process.env.PUBLIC_URL + "/images/" + path
}

export default {
    getImageForWord
}