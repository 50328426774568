import React from 'react'
import queryString from 'query-string'
import Join from "./Join"
import Chat from "./Chat"
import OnePlayer from "./OnePlayer"

import {connect} from 'react-redux'
import Actions from "../state/actions";

class Main extends React.Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        const {onJoin, setName, setRoom, location, onOnePlayer,
        name, room} = this.props

        if (name.length === 0){
            const sName = localStorage.getItem("buzzer_name")
            if (sName) setName(sName)    
        }
        if (room.length === 0){
            const sRoom = localStorage.getItem("buzzer_room")
            if (sRoom) setRoom(sRoom)
        }

        const {mode} = queryString.parse( location.search )
        if (mode=== "SinglePlayer"){
            onOnePlayer()
        }
    }

    handleJoin=()=>{
        const {name,room} = this.props
        localStorage.setItem("buzzer_name", name)
        localStorage.setItem("buzzer_room", room)
        this.props.onJoin()
    }

    render(){
        const {joined, onJoinFailed, showFeedback, onShowFeedback, userNameTaken, onNewUserName,
            onePlayer, onOnePlayer, socket, handleLeave, isAuthenticated,
            onLogin, onSignup, onSubscribe, isExpired
        } = this.props
                    
        return (
            <React.Fragment>
                {joined ? 
                    <Chat socket={socket} 
                        onGameStarted={this.props.onGameStarted}
                        onGameOver={this.props.onGameOver}
                        handleLeave={handleLeave}
                        onPasswordChange={this.props.onPasswordChange}
                        onJoinFailed={onJoinFailed} showFeedback={showFeedback} onShowFeedback={onShowFeedback} />
                    :
                    onePlayer ? <OnePlayer />
                    :
                    <Join 
                        onLogin={onLogin} onSignup={onSignup} onSubscribe={onSubscribe}
                        onJoin={this.handleJoin} userNameTaken={userNameTaken}
                        onNewUserName={onNewUserName} onOnePlayer={onOnePlayer}
                        isAuthenticated={isAuthenticated}
                        isExpired={isExpired} />
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        name : state.name,
        room : state.room,
    }
}
const mapDispatchToProps = {
    setName : Actions.setName,
    setRoom : Actions.setRoom,
}
export default connect(mapStateToProps, mapDispatchToProps)( Main )