import React from "react"

import { Route, Switch, Link} from 'react-router-dom'
import { withRouter } from "react-router";
import queryString from 'query-string'

import Main from "./components/Main"

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChatIcon from '@material-ui/icons/Chat';
import About from './components/About';

import styled from "styled-components"

import ConfirmEndGame from './components/ConfirmMessage';
import SoundButton from './components/soundButton'
import { keyframes } from 'styled-components'

import { ReactComponent as Logo } from "./drawpoly_logo.svg"
// import { ReactComponent as Title } from "./Buzzer_Text_Svg.svg"

import {createMuiTheme, 
  responsiveFontSizes, 
  ThemeProvider as MuiThemeProvider, 
  makeStyles} from "@material-ui/core/styles"
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VolumeUp from '@material-ui/icons/VolumeUp'
import VolumeOff from '@material-ui/icons/VolumeOff'

import ReactGA from 'react-ga'
import config from "./config";

import withTracker from "./withTracker"
import NotFound from "./components/NotFound"
import Replay from "./components/Replay"

import {connect} from 'react-redux'
import Actions from "./state/actions";
import AccountStates from "./components/AccountStates"

import { Auth, API } from "aws-amplify";
import Login from "./components/Login"
import Signup from "./components/Signup"
import Subscribe from "./components/Subscribe"
import Settings from "./components/Settings"

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import io from 'socket.io-client'
const ENDPOINT = config.endpoint
console.log("Using server", ENDPOINT)

if (config.googleTrackingId){
  ReactGA.initialize( config.googleTrackingId )
}

const SettingsWithTracker = withTracker(Settings)
const LoginWithTracker = withTracker(Login)
const SignupWithTracker = withTracker(Signup)
const SubscribeWithTracker = withTracker(Subscribe)
const MainWithTracker = withTracker(Main)
const NotFoundWithTracker = withTracker(NotFound)
const ReplayWithTracker = withTracker(Replay)


// See https://material-ui.com/customization/default-theme/
let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ffc629",
        contrastText: "white",
      },
      secondary: {
        main: "#2958FF"
      },
    },
    typography: {
      textfield: {
        fontSize: 12,
      }
    },
    zIndex: {
      // Make the snackbar (notifications) below the app bar
      appBar: 1100, //Default
      snackbar: 1099
    }
  })
  
  theme = responsiveFontSizes(theme)
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      height: 30,
    },
    spacer: {
      width: "56px"
    },
    menuButton: {
      padding: "0px 0px 0px 12px"
    },
    settings: {
      color: "white"
    },
    tooltip: {
      fontSize: "1rem"
    },
  }))

  const toolTipStyles = makeStyles(theme => ({
    tooltip: {
      fontSize: "1rem"
    },
  }))


// function AdminMenu(props){

//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleMenu = event => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return [
//     <IconButton
//       key="button"
//       className={classes.settings}
//       onClick={handleMenu}
//     >
//       <AccountCircle />
//     </IconButton>,

//   <Menu
//     key="menu"
//     id="admin-appbar"
//     anchorEl={anchorEl}
//     anchorOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     keepMounted
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     open={open}
//     onClose={handleClose}
//     >
//       { props.gameStarted &&
//       <MenuItem onClick={()=>{
//         props.onEndGame()
//         handleClose()
//       }}>End Game</MenuItem>
//       }
//       <MenuItem onClick={()=>{
//         props.onAdminSettings()
//         handleClose()
//       }}>Settings</MenuItem>

//     </Menu>
//     ]

// }



function MenuAppBar(props) {
    const classes = useStyles();
    const toolTipClasses = toolTipStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className={classes.root}>
        <AppBar style={{ background:"#ffc629"}}position="relative">
          <Toolbar>
            <Link to="/" style={{ textDecoration: "none" }}>
              <IconButton style={{padding:0, paddingRight: "10px"}}>
                {/* <a href="https://drawpoly.com/" rel="noopener noreferrer" target="_blank"> */}
                  <Logo style={{height:"45px", width: "45px"}}/>
                {/* </a> */}
              </IconButton>
              </Link>
              {/* <Title className={classes.title}/> */}
              
              <TitleWrapper>
                  {/* <a 
                    style={{
                      textDecoration: "none"
                    }} href="https://drawpoly.com/" rel="noopener noreferrer" target="_blank">
                    <TitleLogo> 
                        DRAWPOLY 
                    </TitleLogo> 
                  </a> */}
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <TitleLogo> 
                  {props.isAuthenticated ?
                  <div>
                      DRAWPOLY  <span style={{ color:"#2958FF"}}>PRO</span> </div>  :
                        "DRAWPOLY"}
                    </TitleLogo> 
                    </Link>
              </TitleWrapper>

              
                <MenuIconsWrapper>
                  <ThinIconButton aria-haspopup="true"
                    className={classes.settings}
                    onClick={props.toggleSound}>
                    {props.mute ? <VolumeOff />  : <VolumeUp />}
                  </ThinIconButton>

                {props.showMenu &&
                  <ThinIconButton
                    aria-haspopup="true"
                    className={classes.settings}
                    onClick={() => {
                      props.showFeedback(true)
                    }}
                  >
                    <ChatIcon  />
                  </ThinIconButton>
                }

                {/* {props.isAuthenticated && <AdminMenu {...props} />} */}
                
                <Tooltip open={props.showAddWordsTooltip} 
                  arrow={true}
                  classes={toolTipClasses}
                  TransitionComponent={Zoom}
                  title="Add custom words in settings!"
                  interactive
                  PopperProps={{
                    onClick:props.closeAddWordsTooltip
                  }}
                  >
                  <ThinIconButton
                    aria-label="user options"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    className={classes.settings}
                  >
                    <MenuIcon />
                  </ThinIconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                
                {props.showMenu &&
                  <MenuItem onClick={()=>{
                    props.onLeaveRoom()
                    handleClose()
                  }}>Leave Room</MenuItem>
                }

                { (props.gameStarted && props.isAuthenticated && props.isAdmin)&&
                  <MenuItem onClick={()=>{
                    props.onEndGame()
                    handleClose()
                  }}>End Game</MenuItem>
                }

                { props.isAuthenticated &&
                  <MenuItem onClick={()=>{
                    props.onAdminSettings()
                    handleClose()
                  }}>Settings</MenuItem>
                }

                { props.isAuthenticated &&
                  <MenuItem onClick={() => {                    
                    props.onLogout()
                    handleClose()
                  }}>Log out</MenuItem>
                }

                {/* {(!props.isAuthenticated) &&
                    <MenuItem onClick={() => {                    
                      props.onLogin()
                      handleClose()
                    }}>Log in</MenuItem>
                }
                {!props.isAuthenticated &&
                    <MenuItem onClick={() => {
                      props.onSignup()
                      handleClose()
                    }}>Sign up</MenuItem>
                } */}
                <MenuItem onClick={() => {
                  console.log("About clicked")
                  props.onAbout(true)
                  handleClose()
                }}>About</MenuItem>
              </Menu>
              </MenuIconsWrapper>
              {/* :
              <div className={classes.spacer} /> */}
              {/* } */}
          </Toolbar>
        </AppBar>
      </div>
    )
  }

class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      joined : false,
      showAbout: false,
      userNameTaken: false,
      showFeedback: false,
      onePlayer: false,
      isAuthenticated: false,
      isAuthenticating: true,
      adminControlsOpen: false,
      connecting: true,
      showConfirmEndGame: false,
      showAddWordsTooltip: false,
    }

    this.socket = null
  }

  async componentDidMount(){

    if (config.googleTrackingId){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    const sMute = localStorage.getItem("buzzer_mute")
    if (sMute !== undefined || sMute !== null){
      this.props.setMute( sMute === 'true' ? true : false )
    }

    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
      this.userHasAuthenticated(false)
    }

    // https://stackoverflow.com/questions/32963400/android-keyboard-shrinking-the-viewport-and-elements-using-unit-vh-in-css
    setTimeout(function () {
      let viewheight = window.innerHeight;
      let viewwidth = window.innerWidth;
      let viewport = document.querySelector("meta[name=viewport]");
      // console.log("Setting wh", viewwidth, viewheight)
      viewport.setAttribute("content", "height=" + viewheight + ", width=" + viewwidth + ", initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0");
    }, 300);
  }

  componentWillUnmount(){
    console.log("Leaving")        
    if(this.socket && this.socket.connected){
      this.socket.disconnect()
      this.socket.off()
    }
  }

  connectToGameServer=()=>{
    return new Promise( (resolve, reject)=>{
      this.setState({connecting:true})
  
      if (this.socket){
        this.socket.disconnect()
        this.socket.off()
      }
  
      this.socket = io(ENDPOINT)

      this.socket.once("connect", ()=>{
        console.log("Connected")
        resolve()
      })
  
      const unconnectEvents = ["connect_timeout", "connect_error", 
              "reconnect_attempt", "reconnect_error", "reconnect_failed",
              "close"]
  
      unconnectEvents.forEach(e=>{
              this.socket.on(e, ()=>{this.setState({connecting:true})})
          })
      
      const connectedEvents = ["connect", "reconnect"]
      connectedEvents.forEach(e=>{
          this.socket.on(e, ()=>{this.setState({connecting:false})})
      })

      const loggedEvents = ["open", "connect", "connect_timeout", "connect_error", 
              "reconnect_attempt", "reconnect", "reconnect_error", "reconnect_failed",
              "close"
        ]
      loggedEvents.forEach(e=>{
          this.socket.on(e, ()=>console.log(e, this.socket.id))
      })
    })
  }

  handleNewSettings = async ()=>{
    const {setName, setRoom, setCustomCategories, setAdminMagicPassword, setAutoCheckGuess, setAccountState} = this.props
    try{
      const result = await API.get("api", "/settings")
      console.log("Settings:", result)

      setName( result.Settings.DisplayName )
      setRoom( result.Settings.RoomName )
      setCustomCategories( result.Settings.Categories )
      setAutoCheckGuess( result.Settings.AutoCheckGuess)
      setAccountState( result.Settings.AccountState)
      
      if (this.props.adminMagicPassword !== result.Settings.Password){
        console.log("New password detected", this.socket)
        setAdminMagicPassword(result.Settings.Password)
        // Notify the game server that the password has changed
        if (this.socket && this.socket.connected){
          console.log("Already connected")
        } else {
          console.log("Connecting to server")
          await this.connectToGameServer()
        }
        
        this.socket.emit('passwordChanged', {
          password:result.Settings.Password, 
          room:result.Settings.RoomName
        })
      }
      
    } catch (error){
      console.log("Failed to load settings", error)
    }
  }

  handlePasswordChange=async(word)=>{
    // Update the password in settings
    const settings = {
        DisplayName: this.props.name,
        RoomName: this.props.room,
        Password: word,
        Categories: this.props.customCategories,
        AutoCheckGuess: this.props.autoCheckGuess
    }

    try{
      await API.put("api", `/settings`, {
        body: {
            ...settings
        }
      })

      this.props.setAdminMagicPassword(word)
      this.socket.emit('passwordChanged', {password:word, room:this.props.room})
    }catch(error){
      console.log("Failed to update password")
    }
  }

  userHasAuthenticated = async authenticated => {

    if (authenticated){
      // Get the settings and set the name and room
      await this.handleNewSettings()
      // this.handleJoin()

      setTimeout(()=>{
        if (this.props.customCategories.length === 0){
          this.setState({
            showAddWordsTooltip: true
          })

          setTimeout(()=>{
            this.setState({
              showAddWordsTooltip: false
            })
          }, 5000)
        }
      }, 1000);

    } else {
      const {setName, setRoom, location} = this.props
      const {name, room} = queryString.parse( location.search )
      if (name) setName(name)
      if (room) setRoom(room)
    }

    this.setState({
      isAuthenticating: false,
      isAuthenticated: authenticated,
    });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/");

    this.setState({
      joined:false,
      gameStarted: false,
    })
  }

  handleLogin =()=>{
    this.setState({
      joined:false,
      gameStarted: false,
    })

    this.props.history.push("/login");
  }

  handleSignup =()=>{
    this.setState({
      joined:false,
      gameStarted: false,
    })

    this.props.history.push("/signup");
  }


  handleSubscribe = () => {
    this.setState({
      joined: false,
      gameStarted: false,
    })

    this.props.history.push("/subscribe");
  }

  handleToggleSound=()=>{
    const mute = !this.props.mute
    this.props.setMute( mute )
    localStorage.setItem("buzzer_mute", mute ? "true" : "false")
  }

  
  handleEndGame=()=>{
    this.setState({ 
      showConfirmEndGame: false
    })
    console.log("End Game")
    if (this.socket){
      this.socket.emit('endGame', {} , (event)=>{
        const {error} = event
        if (error){
            console.log(error)
        }
    })
    }
  }

  fuzzyNameCompare=(aa, bb)=>{
    aa = aa.split(" ").join("").toLowerCase()
    bb = bb.split(" ").join("").toLowerCase()
    return aa === bb
  }

  handleJoin= async ()=>{
    console.log("Joining")

    try{
      let result = await API.get("api", "/rooms/" + encodeURI(this.props.room))
      console.log("RoomData:", result)
      if (result.isPrivate && result.isAdmin === false){
        // Check that we are not taking the admin name
        if (this.fuzzyNameCompare(this.props.name, result.adminName)){
          console.log("Error, name too close to admin name")
          this.handleJoinFailed()
          return
        }
      } 
  
      this.props.setRoomData(result)  
    } catch (err){
      console.log("Error getting room data", this.props.room, err )
      this.handleJoinFailed()
      return;
    }

    await this.connectToGameServer()

    this.setState(ss=>({
      joined:true,
    }))
  }

  handleOnePlayer = () => {
    this.setState({ onePlayer: true })
  }

  handleLeave=async ()=>{
    await this.handleNewSettings()
    this.setState({
      joined:false,
      gameStarted: false,
    })
  }

  handleAbout = (showAbout) => {
    console.log("handleAbout", showAbout)
    this.setState({ showAbout })
  }

  handleUserNameTaken=()=>{
    this.setState({userNameTaken: true})
    this.handleLeave()
  }

  handleJoinFailed=()=>{
    this.handleUserNameTaken()
    this.setState({
      connecting:false
    })
  }

  handleNewUserName = () => {
    this.setState({ userNameTaken: false })
  }

  handleShowFeedback = (showFeedback) => {
    this.setState({ showFeedback: showFeedback })
  }

  handleGameStart=()=>{
    this.setState({
      gameStarted:true
    })
  }

  handleGameOver=()=>{
    this.setState({
      gameStarted:false
    })
  }


  handleConfirmEndGame = () => {
    this.setState({
      showConfirmEndGame: true
    })
  }

  handleAdminSettings = () => {
    this.props.history.push("/settings");
  };

  confirmEndGame=()=> {
  return (
    <ConfirmEndGame type={"endGame"} onAccept={this.handleEndGame} onReject={() => this.setState({ showConfirmEndGame: false })} />
  )
}

tryPro=()=>{
  return(
    <Pro style={{
      marginTop: "10px",
      padding: "10px",
      background: "rgb(240,240,240)",
      textAlign:"center",
      border: "solid 0px #2958FF",
      color:"rgb(50,50,50)"
    }} >
      <Anim>
        <div style={{ fontSize: "15px", paddingBottom: "10px" }}> 
        {/* TRY  <span style={{ color: "#2958FF" }}> PRO</span>  */}
        
          Custom words, private rooms + more! </div> </Anim>
  {/* <div style={{ marginLeft:"auto", marginRight:0, textAlign:"right"}}> */}
      <SoundButton text={"Sign Up"} color="secondary" 
        style={{
          background: "rgb(248,248,248)",
          margin: "5px",
          fontSize: "10px",
          color: "rgb(50,50,50)",
          height:"30px"
        }}
        onClick={() => this.handleSignup()}
      />
      <SoundButton text={"Log In"} color="secondary" 
        style={{
          background: "rgb(248,248,248)",
          fontSize: "10px",
          margin: "5px",
          color: "rgb(50,50,50)",
          height: "30px"
        }}
        onClick={() => this.handleLogin()}
      />
      {/* </div> */}
    </Pro>
  )
}

  onfreeTrial = () => {
    return (
      <Pro style={{
        marginTop: "10px",
        padding: "10px",
        background: "rgb(240,240,240)",
        textAlign: "center",
        border: "solid 0px #2958FF",
        color: "rgb(50,50,50)"
      }} >
        <Anim>
          <div style={{ fontSize: "15px", paddingBottom: "10px" }}>
            {/* TRY  <span style={{ color: "#2958FF" }}> PRO</span>  */}

            Your <span style={{ color: "#ffc629" }}> DRAWPOLY </span> <span style={{ color: "#2958FF" }}> PRO</span> free trial is about to end! </div> </Anim>
        {/* <div style={{ marginLeft:"auto", marginRight:0, textAlign:"right"}}> */}
        <SoundButton text={"Subscribe"} color="secondary"
          style={{
            background: "rgb(248,248,248)",
            margin: "5px",
            fontSize: "10px",
            color: "rgb(50,50,50)",
            height: "30px"
          }}
          onClick={() => this.handleSubscribe()}
        />
        {/* </div> */}
      </Pro>
    )
  }


  render(){

    const isOnFreeTrial = this.props.accountState === AccountStates.ACTIVE_FREE_TRIAL
    const isExpired = this.props.accountState === AccountStates.EXPIRED_FREE_TRIAL ||
      this.props.accountState === AccountStates.EXPIRED_SUBSCRIPTION

    const mainProps = {
      isExpired,
      joined:this.state.joined,
      onJoin:this.handleJoin,
      onJoinFailed:this.handleJoinFailed,
      onNewUserName:this.handleNewUserName,
      userNameTaken:this.state.userNameTaken,
      showFeedback:this.state.showFeedback,
      onShowFeedback:this.handleShowFeedback,
      onePlayer:this.state.onePlayer,
      onOnePlayer:this.handleOnePlayer,
      onGameStarted:this.handleGameStart,
      onGameOver:this.handleGameOver,
      isAuthenticated:this.state.isAuthenticated,
      userHasAuthenticated: (auth)=>{
        this.userHasAuthenticated(auth)
      },
      connecting:this.state.connecting,
      handleLeave:this.handleLeave,
      onNewSettings:this.handleNewSettings,
      onLogin:this.handleLogin,
      onSignup:this.handleSignup,
      onSubscribe:this.handleSubscribe,
      onPasswordChange:this.handlePasswordChange,
      // onJoinPrivateRoom:this.handleJoinPrivateRoom,
    }

    if (this.state.isAuthenticating){
      return (null)
    }

    return (
      <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Container style={{padding:0}}maxWidth="sm">
              <MenuAppBar 
                mute={this.props.mute}
                toggleSound={this.handleToggleSound}
                onLeaveRoom={this.handleLeave} 
                onAbout={this.handleAbout}
                onLogin={this.handleLogin}
                onSignup={this.handleSignup}
                showMenu={this.state.joined} 
                showFeedback={this.handleShowFeedback} 
                onAdminSettings={this.handleAdminSettings}
                onEndGame={this.handleConfirmEndGame}
                gameStarted={this.state.gameStarted}
                onLogout={this.handleLogout}
                isAuthenticated={this.state.isAuthenticated}
                isAdmin={this.props.roomData.isAdmin}
                showAddWordsTooltip={this.state.showAddWordsTooltip}
                closeAddWordsTooltip={()=>this.setState({showAddWordsTooltip:false})}
                />
          {(!this.state.isAuthenticated && 
            !this.state.joined &&
            !this.state.onePlayer &&
            this.props.location.pathname !== "/signup" &&
            this.props.location.pathname !== "/login") && this.tryPro()}

          {(this.state.isAuthenticated &&
            isOnFreeTrial &&
            !this.state.joined &&
            !this.state.onePlayer &&
            this.props.location.pathname !== "/signup" &&
            this.props.location.pathname !== "/subscribe" &&
            this.props.location.pathname !== "/login") && this.onfreeTrial()}
              <Wrapper>
                  <Switch>
                      {/* Not sure why but when we use Main with withTracker, we end up in an infinite refreshing loop  */}
                      {/* It seems to be related to Main calling onJoin when we have a queryString for name and room */}
                      {/* Leaving out the tracker for now due to time constraints */}
                      {/* <Route path="/" exact props={mainProps}>
                        {withTracker(Main)}
                      </AppliedRoute> */}
                      {/* SOLUTION - use children prop - https://medium.com/@migcoder/difference-between-render-and-component-prop-on-react-router-v4-368ca7fedbec */}
                      {/* https://tylermcginnis.com/react-router-pass-props-to-components/ */}

                      <Route path="/" exact render={props=> <MainWithTracker socket={this.socket} {...props} {...mainProps} />} />
                      <Route path="/login" exact render={props=> <LoginWithTracker {...props} {...mainProps} />} />
                      <Route path="/signup" exact render={props=> <SignupWithTracker {...props} {...mainProps} />} />
                      <Route path="/subscribe" exact render={props => <SubscribeWithTracker {...props} {...mainProps} />} />  
                      <Route path="/settings" exact render={props=> <SettingsWithTracker {...props} {...mainProps} />} />
                      <Route path="/replay/:id" exact render={props=><ReplayWithTracker {...props} {...mainProps} />} />
                      <Route component={NotFoundWithTracker} />
                  </Switch>
                  {this.state.showAbout && <About onClose={()=>this.setState({showAbout:false})} />}
                  {this.state.showConfirmEndGame && this.confirmEndGame() }
              </Wrapper>
          </Container>
      </MuiThemeProvider>
    )
  }
}



const mapStateToProps = state => {
  return {
      name : state.name,
      room : state.room,
      mute : state.mute,
      customCategories : state.customCategories,
      autoCheckGuess : state.autoCheckGuess,
      accountState : state.accountState,
      roomData: state.roomData,
      adminMagicPassword: state.adminMagicPassword
  }
}

const mapDispatchToProps = {
  setMute : Actions.setMute,
  setName : Actions.setName,
  setRoom : Actions.setRoom,
  setCustomCategories : Actions.setCustomCategories,
  setAutoCheckGuess : Actions.setAutoCheckGuess,
  setAccountState : Actions.setAccountState,
  setRoomData : Actions.setRoomData,
  setAdminMagicPassword : Actions.setAdminMagicPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)( withRouter(App) )

const ThinIconButton = styled(IconButton)`
  padding: 6px;
`

const Wrapper = styled.div`
    position:relative;
    text-align: center;
    font-family: "Nanum Gothic", sans-serif;
    /* user-select: none; */
    margin: 20px;
    font-size: 20px;
`

const TitleWrapper = styled.span`
  /* margin: auto;
  margin-left:15px; */
  flex-grow: 1;
`

const MenuIconsWrapper = styled.div`
  text-align: right;
`

const TitleLogo = styled.div`
    /* margin: 5px; */
    letter-spacing: 2px;
    color:white;
    font-size: 20px;
    font-family: 'Baloo Chettan 2', cursive;
    /* text-decoration: none; */
`

const pulseAnimation = keyframes`
 0% { transform: scale(1.0); }
 5% { transform: scale(1.1);  }
 10% { transform: scale(1.0);  }
 15% { transform: scale(1.1);  }
 20% { transform: scale(1.0); }
`

const Pro = styled.div`
    letter-spacing: 2px;
    font-size: 18px;
    font-family: 'Baloo Chettan 2', cursive;
`

const Anim = styled.div`
    animation-name: ${pulseAnimation};
    animation-duration: 5.0s;
    animation-iteration-count: 2;
    transform: scale(1.0);
`