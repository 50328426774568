
const dev = {
    endpoint : 'http://localhost:5000',
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_I7MdvDRxR",
        APP_CLIENT_ID: "4qccjkg89tib4koej2qj809gh6",
        IDENTITY_POOL_ID: "us-west-2:944145e7-3144-495d-8ba2-0217f473b802"
    },
    apiGateway: {
        api: {
          REGION: "us-west-2",
          URL: "https://cnvvrlpu4b.execute-api.us-west-2.amazonaws.com/dev"
        }
    },
}

const staging = {
    googleTrackingId : "UA-165680260-2",
    endpoint : "http://dev.drawpoly.com",
    // Same as dev for now
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_I7MdvDRxR",
        APP_CLIENT_ID: "4qccjkg89tib4koej2qj809gh6",
        IDENTITY_POOL_ID: "us-west-2:944145e7-3144-495d-8ba2-0217f473b802"
    },
    apiGateway: {
        api: {
          REGION: "us-west-2",
          URL: "https://cnvvrlpu4b.execute-api.us-west-2.amazonaws.com/dev"
        }
    },
}

const prod = {
    googleTrackingId : "UA-165680260-1",
    endpoint : "https://app.drawpoly.com",
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_1NbSH7NBv",
        APP_CLIENT_ID: "3jvf2emaamgn92l106o0rpq9bh",
        IDENTITY_POOL_ID: "us-west-2:b6e9c58d-b646-42ae-b18b-d7c77f0af9e9"
    },
    apiGateway: {
        api: {
          REGION: "us-west-2",
          URL: "https://upwkh5c1yc.execute-api.us-west-2.amazonaws.com/prod"
        }
    },
}

const config = process.env.REACT_APP_STAGE === 'prod' ? prod 
    : process.env.REACT_APP_STAGE === 'staging' ? staging    
    : dev

export default {
    ...config
}
