import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from "react-redux";
import store from "./state/store";

import Amplify from "aws-amplify";
import config from "./config";

import App from "./app"

import { BrowserRouter as Router } from 'react-router-dom'

Amplify.configure({
    Auth: {      
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
          {
            name: "api",
            endpoint: config.apiGateway.api.URL,
            region: config.apiGateway.api.REGION
          }
        ]
      }
})

ReactDOM.render(
    // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    // </React.StrictMode>
    , document.querySelector("#root"))
