import React, { Component } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components'

var isTouchDevice = require('is-touch-device');

const mouse_to_coords=(elem, mouse_pos)=>{
    let rect = elem.getBoundingClientRect()
    return {
        x: mouse_pos.x - rect.left,
        y: mouse_pos.y - rect.top
    }
}

class Canvas extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isDrawing : false
        }
        this.mouse_pos = {x:0, y:0}
    }

    paint=(prev, curr, strokeStyle, width)=> {
        const canvasPrev = mouse_to_coords(this.canvas, prev)
        const canvasCurr = mouse_to_coords(this.canvas, curr)

        const newStroke = {
            c: strokeStyle, w: width, m: [canvasPrev.x, canvasPrev.y],
            l: [canvasCurr.x, canvasCurr.y]
        }
        
        this.props.onStroke(newStroke)
        this.draw(newStroke)
    }

    draw =(s)=>{
        this.ctx.beginPath();
        this.ctx.strokeStyle = s.c;
        this.ctx.moveTo(s.m[0], s.m[1]);
        this.ctx.lineTo(s.l[0], s.l[1]);
        this.ctx.lineWidth = s.w;
        this.ctx.stroke();
    }

    componentDidMount() {
        // Here we set up the properties of the canvas element. 
        if (isTouchDevice()){
            disableBodyScroll(this.canvas.current)
        }
        this.canvas.width = this.props.width
        this.canvas.height = this.props.height
        this.ctx = this.canvas.getContext('2d');
        this.ctx.lineJoin = 'round';
        this.ctx.lineCap = 'round';
        this.ctx.lineWidth = this.props.active_brush;
        this.ctx.fillStyle = this.props.active_background_color;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        // Overlay
        this.overlayCanvas.width = this.props.width
        this.overlayCanvas.height = this.props.height
        this.overlayCtx = this.overlayCanvas.getContext('2d');
        this.overlayCtx.lineJoin = 'round';
        this.overlayCtx.lineCap = 'round';
        this.overlayCtx.lineWidth = this.props.active_brush;
        this.overlayCtx.fillStyle = "rgba(255,255,255,0)"
        this.overlayCtx.fillRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height);

        this.canvas.addEventListener('mousemove', this.handleMouseMove, false);
        document.addEventListener('mouseup', this.handleMouseUp, false);
        this.canvas.addEventListener('mousedown', this.handleMouseDown, false);

        this.canvas.addEventListener("touchmove", this.handleTouchMove, false );
        document.addEventListener("touchend", this.handleTouchEnd, false);
        document.addEventListener("touchcancel", this.handleTouchEnd, false);
        this.canvas.addEventListener("touchstart", this.handleTouchStart, false);
    }

    componentWillUnmount(){
        clearAllBodyScrollLocks()
        this.canvas.removeEventListener('mousemove', this.handleMouseMove, false);
        document.removeEventListener('mouseup', this.handleMouseUp, false);
        this.canvas.removeEventListener('mousedown', this.handleMouseDown, false);

        this.canvas.removeEventListener("touchmove", this.handleTouchMove, false );
        document.removeEventListener("touchend", this.handleTouchEnd, false);
        document.removeEventListener("touchcancel", this.handleTouchEnd, false);
        this.canvas.removeEventListener("touchstart", this.handleTouchStart, false);
    }

    handleMouseMove =(e)=>{
        e.preventDefault();
        let m = e
        let mouse_pos = {
            x: m.clientX,
            y: m.clientY,
        }

        let prev_pos = {...this.mouse_pos}
        this.mouse_pos = mouse_pos

        this.handleMove(prev_pos, mouse_pos)
    }

    handleTouchMove =(e)=>{
        e.preventDefault();
        e.stopPropagation();

        let m = e.touches[0]
        let mouse_pos = {
            x: m.clientX,
            y: m.clientY,
        }

        let prev_pos = {...this.mouse_pos}
        this.mouse_pos = mouse_pos

        this.handleMove(prev_pos, mouse_pos)
    }

    handleMove=(prev,curr)=>{
        if(this.state.isDrawing){
            // this.ctx.lineWidth = this.props.active_brush;
            this.paint(prev, curr, this.props.active_color, this.props.active_brush);

            //show brush
            const canvasCurr = mouse_to_coords(this.canvas, curr)

            this.overlayCtx.clearRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height);
            this.overlayCtx.beginPath();
            this.overlayCtx.strokeStyle = 'black';
            this.overlayCtx.lineWidth = 1;
            this.overlayCtx.arc(canvasCurr.x, canvasCurr.y, this.props.active_brush/2, 0, 2 * Math.PI);
            this.overlayCtx.stroke();
        } 
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        this.handleDown(e)
    }

    handleTouchStart =(e)=>{
        e.preventDefault();
        this.handleDown(e)
    }

    handleDown=(e)=>{
        let m = e
        if (e.touches) {
            m = e.touches[0]
        }
    
        let mouse_pos = {
            x: m.clientX,
            y: m.clientY,
        }
        this.mouse_pos = mouse_pos
        this.setState({isDrawing:true})
        this.props.handleBrushOptions(false)

        this.paint(mouse_pos, {x:mouse_pos.x, y:mouse_pos.y+1}, 
            this.props.active_color, this.props.active_brush);
    }

    handleMouseUp=(e)=>{
        this.handleUp(e)
    }

    handleTouchEnd=(e)=>{
        this.handleUp(e)
    }

    handleUp=(e)=>{
        this.overlayCtx.clearRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height);
        this.setState({isDrawing:false})
    }

    render() {
        // We use the ref attribute to get direct access to the canvas element. 
        // https://github.com/facebook/react/issues/9328
        // 
        return (
            <CanvasWrapper style={{width: `${this.props.width+8}px`, height:`${this.props.height+8}px`}}>
                <MainCanvas style={{width: `${this.props.width}px`, height:`${this.props.height}px`}}>
                    <canvas ref={ref =>this.canvas = ref}  style={{touchAction:'none'}} />
                </MainCanvas>
                
                <OverlayCanvas style={{width: `${this.props.width}px`, height:`${this.props.height}px`}}>
                    <canvas ref={ref =>this.overlayCanvas = ref} style={{touchAction:'none', backgroundColor: "transparent" }} />
                </OverlayCanvas>

            </CanvasWrapper>
        )
    }
}

const CanvasWrapper = styled.div`
    position:relative;
    border-style: solid;
    border-color: rgb(210,210,210);
    border-width: 4px;
    border-radius: 5px; 
    margin: 0px;
    padding: 0px;
`

const MainCanvas = styled.div`
    background: white;
`

const OverlayCanvas = styled.div`
    position:absolute;
    top:0;
    left:0;
    /* transform: translate(-50%, 0%); */
    margin-left: 0px;
    touch-action: none;
    pointer-events: none;
    background-color: transparent;
`

export default Canvas;
