
const UserStates = {
    ENTER_PASSWORD : 'ENTER_PASSWORD',
    WAITING_NO_GAME : 'WAITING_NO_GAME',
    CATEGORY_VOTING: 'CATEGORY_VOTING',
    DRAWER_WORD_INPUT : "DRAWER_WORD_INPUT",
    DRAWER_CANVAS_INPUT: "DRAWER_CANVAS_INPUT",
    DRAWER_DRAWING : "DRAWER_DRAWING",
    GUESSER_WORD_WAITING : "GUESSER_WORD_WAITING",
    GUESSER_WATCHING : "GUESSER_WATCHING",
    GUESSER_GUESS_INPUT : "GUESSER_GUESS_INPUT",
    GUESSER_GUESS_APPROVAL_WAITING : "GUESSER_GUESS_APPROVAL_WAITING",
}

export default UserStates