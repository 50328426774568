import React from "react"

import ReactGA from "react-ga"
import config from "./config";
const withTracker = (WrappedComponent, options={})=>{
    const trackPage = page =>{
      if (config.googleTrackingId){
        ReactGA.set({
          page,
          ...options
        })
        ReactGA.pageview(page)
      } else {
        console.log("Tracking GA page view", page, options)
      }
    };
  
    const HOC = class extends React.Component {
      componentDidMount(){
        const page = this.props.location.pathname;
        trackPage(page)
      }
  
      componentWillReceiveProps(nextProps){
        const currentPage = this.props.location.pathname
        const nextPage = nextProps.location.pathname
        if (currentPage !== nextPage){
          trackPage(nextPage)
        }
      }
  
      render(){
        return <WrappedComponent {...this.props} />
      }
    }
  
    return HOC;
  }

export default withTracker
