import React, { Component } from 'react';

class CanvasGuesser extends Component {

    constructor(props){
        super(props)
        this.state = {
            maxStrokeIndex : props.static ? props.strokes.length : -1,
            lastDrawnLength : 0
        }
    }

    componentDidUpdate(){
        if (this.props.strokes.length > this.state.lastDrawnLength){
            this.updateRender()
        }
    }
   
    updateRender=()=>{
        let strokes = this.props.strokes
        
        const strokeLength = strokes.length;
        for(let i=this.state.lastDrawnLength; i<strokeLength; ++i){
            let s = strokes[i]
            if (this.props.static && i > this.state.maxStrokeIndex){
                // Ignore
            } else {
                this.ctx.beginPath();
                this.ctx.strokeStyle = s.c;
                this.ctx.moveTo(s.m[0]*this.props.scale, s.m[1]*this.props.scale);
                this.ctx.lineTo(s.l[0]*this.props.scale, s.l[1]*this.props.scale);
                this.ctx.lineWidth = s.w * this.props.scale;
                this.ctx.stroke();
            }
        }
        
        this.setState({
            lastDrawnLength : strokeLength
        })
    }

    componentDidMount() {
        // Here we set up the properties of the canvas element. 
        this.canvas.width = this.props.width *this.props.scale //320;
        this.canvas.height = this.props.height * this.props.scale //520;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.lineJoin = 'round';
        this.ctx.lineCap = 'round';
        this.ctx.lineWidth = this.props.active_brush;
        this.ctx.fillStyle = this.props.active_background_color;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        this.updateRender()
    }



    render() {
        return (
            <canvas
            // We use the ref attribute to get direct access to the canvas element. 
            ref={ref =>{
                this.canvas = ref
            }}
                style={{ background: 'white', borderStyle:'solid', borderColor:'rgb(210,210,210)',
                borderWidth:'4px', borderRadius:'5px', marginLeft:'0px'}}
            /> 
        )
    }
}
export default CanvasGuesser;
