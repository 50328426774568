import React, { Component } from 'react';
import styled from "styled-components"
import ColorPicker from './colorPicker';
import BrushPicker from './brushPicker';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


class brushOptions extends Component {
  constructor(props) {
    super(props);

    this.state={
      
    }
  }

  render() {
    return (
        <Wrapper>
            <CancelTurn>
                    {this.props.onePlayer ? 
                        <GiveUp size={40} onClick={() => this.props.done()} >
                            Done 
                        </GiveUp>
                        : 
                        <GiveUp size={40} onClick={() => this.props.giveUpTurn()} >
                            Give Up
                        </GiveUp>
                        
                        }
                        
            </CancelTurn>
            {this.props.bushOptionsOpen ? 
            <Menu>
                    <IconButton
                        aria-haspopup="true"
                        onClick={() => this.props.handleBrushOptions(false)}
                        style={{
                            float: "left",
                            }} >
                        <CloseIcon />
                    </IconButton>
                    
                    <Eraser size={100} onClick={() => {
                        this.props.handleBrushChange(45)
                        this.props.handleColorChange(this.props.active_background_color)
                        // this.handleOpenOptions()
                        } } >
                Eraser
                    </Eraser>
                <BrushPicker 
                    active_brush={this.props.active_brush}
                    active_color={this.props.active_color}
                    handleBrushChange={this.props.handleBrushChange} />
                <ColorPicker 
                    active_color={this.props.active_color}
                    handleColorChange={this.props.handleColorChange} />
            </Menu>
            :
                <MenuClosed>
                    <Collapse size={40} onClick={() => this.props.handleBrushOptions(true)} >
                        Brush
                    </Collapse>
                </MenuClosed>
            }

            
        </Wrapper>
    );
  }
}



const Menu = styled.div`
  background-color: rgb(230,230,230);
  border-radius: 5px;
  border-color:  #c7c7c7;
  border-width: '1px';
  border-style: solid;
  position:absolute;
  /* top:0; */
  /* top:200px; */
  /* left:10px; */
  /* margin: 7px; */

`

const Collapse = styled.div`
    padding: ${p => `${p.size * 0.2}px`};
    background-color: #ffc629;
    color: white;
    border-radius: 5px;
    cursor: pointer;
`

const GiveUp = styled.div`
    padding: ${p => `${p.size * 0.2}px`};
    background-color: rgb(200,200,200);
    color: white;
    border-radius: 5px;
    cursor: pointer;
`

const Eraser = styled.div`
    float: left;
    margin-left: 10px;
    padding: 10px;
    background-color: #ee96aa;
    border-radius: 10px;
    color: white;
    user-select: none;
    cursor: pointer;
`
const MenuClosed = styled.div`
  /* top: 61px; */
   margin: 7px;
   position:absolute;
`; 

const CancelTurn = styled.div`
   /* top: 61px; */
   /* right:0px; */
   /* left: 75px; */
   right: 0px;
   margin: 7px;
   position:absolute;
`;

const Wrapper = styled.div`
  text-align: center;
  z-index: 100;
  position: relative;
  font-size: 20px;
  font-family: 'Baloo Chettan 2', cursive;
`; 

export default brushOptions;