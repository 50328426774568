import React, { Component } from "react";
import styled from "styled-components"

export default class NotFound extends Component {
  
  constructor(props){
    super(props)
    this.timer = null
    this.state = {
      redirectTime : 5
    }
  }

  componentDidMount(){
    this.timer = setInterval( ()=>{
      let nextTime = this.state.redirectTime - 1
      if (nextTime === 0) {
        clearInterval(this.timer)
        this.timer = null
        this.props.history.push("/")
      } else {
        this.setState({
          redirectTime : nextTime
        })
      }
    }, 1000 )
  }

  componentWillUnmount(){
    if (this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
  }

  render(){
    return (
      <Wrapper>
        <h3>Opps, page not found!</h3>
        <h6>Redirecting in {this.state.redirectTime.toString() + " seconds."} </h6>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
    padding-top: 100px;
    text-align: center;
`