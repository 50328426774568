import React from "react"
import Lottie from "react-lottie"

import styled from 'styled-components'

export default class LottieControl extends React.Component {
   
    render() {

      const defaultOptions = {
        loop: this.props.loop ? this.props.loop : false,
        autoplay: true, 
        animationData: this.props.animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
   
      // console.log(defaultOptions)

      let listeners = []
      if (this.props.onComplete){
        listeners = [
          {
            eventName: 'complete',
            callback:this.props.onComplete
          }
        ]
      }

      return (
        <NoClick>
          <Lottie options={defaultOptions}
                    width={"100%"}
                    eventListeners={listeners} />
        </NoClick>
      )
  }
}

const NoClick = styled.div`
  pointer-events: none;
`