import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory} from 'react-router-dom'
import { withRouter } from "react-router";
import { Auth } from "aws-amplify";

const FormModes = {
    LOGIN : "LOGIN", 
    SIGNUP : "SIGNUP"
}

const Login =(props)=>{

    const [mode,setMode] = useState(FormModes.LOGIN)

    const history = useHistory()
    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorString, setErrorString] = useState("")

    const submit= async e =>{
        e.preventDefault()
        setErrorString("")
        setIsLoading(true)

        if (mode === FormModes.LOGIN){
            if (email.length === 0){
                setErrorString("Email required")
                setIsLoading(false)
                return
            }
            if (password.length === 0){
                setErrorString("Password required")
                setIsLoading(false)
                return
            }

            if (password.length !== 0){
                try {
                    await Auth.signIn(email, password);
                    setIsLoading(false)
                    await props.userHasAuthenticated(true);
                    history.push("/");
                } catch (e) {
                    alert(e.message);
                    setIsLoading(false)
                }
            }
        }

        if (mode === FormModes.SIGNUP){
            if (email.length === 0){
                setErrorString("Email required")
                setIsLoading(false)
                return
            }
            if (password.length === 0 || password !== confirmPassword){
                setErrorString("Passwords do not match")
                setIsLoading(false)
                return
            }
            
            try {
                await Auth.signUp({
                    username: email,
                    password: password
                });
                await Auth.signIn(email, password);
                setIsLoading(false)
                await props.userHasAuthenticated(true);

            } catch (e) {
                alert(e.message);
                setIsLoading(false)
            }
        }
    }

    return (
        <Wrapper>
            <SectionWrapper>
                <Section>
            { mode === FormModes.LOGIN && <h1>Log in</h1> }
            { mode === FormModes.SIGNUP && <h1>Sign up</h1> }
            <form onSubmit={submit}>
            
                <TextField
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        name='email'
                        onChange={e => setEmail(e.target.value.trim())}
                        value={email}
                        style={{padding:"10px 0px"}}
                        autoFocus
                        label="Email" />
                    <br/>
                <TextField
                        type="password"
                        variant="outlined"
                        name='password'
                        style={{ padding: "10px 0px" }}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        label={"Password"} />
                    <br/>
                {mode === FormModes.SIGNUP &&
                    <TextField
                        type="password"
                        variant="outlined"
                        name='confirmPassword'
                        style={{ padding: "10px 0px" }}
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        label="Confirm Password" />
                }
                    <br/>
                
                { errorString.length > 0 && <ErrorDiv>
                    {errorString}
                </ErrorDiv>}

                {isLoading ? <CircularProgress />
                :    
                <Button variant="contained" type="submit" color="primary" style={{ height: "40px", margin: "10px 0px", fontWeight: 'bolder' }}>
                    { mode === FormModes.LOGIN && ("Log in") }
                    { mode === FormModes.SIGNUP && ("Sign Up") }
                </Button>
                }
            </form>
                </Section>
            </SectionWrapper>
            <OR>OR</OR>
            { mode === FormModes.LOGIN &&
                <SectionWrapper>
                    <Section>
                        Don't have an account yet?
                        <br></br>
                        <Button variant="contained" type="button" 
                            onClick={()=>{
                                console.log("Signup")
                                // setMode(FormModes.SIGNUP)
                                props.history.push("/signup");
                                window.scrollTo(0, 0)
                            }}
                            color="primary" style={{ height: "40px", margin: "10px 0px", fontWeight: 'bolder' }}>
                            Sign up
                        </Button>
                    </Section>
                </SectionWrapper>
            }
            { mode === FormModes.SIGNUP &&
                <SectionWrapper>
                    <Section>
                        Already have an account?
                        <br></br>
                        <Button variant="contained" type="button" 
                            onClick={()=>{
                                setMode(FormModes.LOGIN)
                                window.scrollTo(0, 0)
                            }}
                            color="primary" style={{ height: "40px", margin: "10px 0px", fontWeight: 'bolder' }}>
                            Log in
                        </Button>
                    </Section>
                </SectionWrapper>
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
    text-align: center;
    font-size: 20px;
    background-color: #f8f8f8;
`

const ErrorDiv = styled.div`
    color: red;
`

const OR = styled.div`
    font-size: 26;
    margin-bottom: 40px;
    margin-top: 40px;
`

const Section = styled.div`
    margin-top: 20px;    
`

const SectionWrapper = styled.div`
    background-color: white;
    /* padding: 20px 15%; */
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    
`

export default withRouter(Login)