export default {
    SET_NAME: 'SET_NAME',
    SET_ROOM: 'SET_ROOM',
    ADD_STROKE: 'ADD_STROKE',
    CLEAR_STROKES: 'CLEAR_STROKES',
    SET_MUTE: 'SET_MUTE',
    SET_CUSTOM_CATEGORIES: 'SET_CUSTOM_CATEGORIES',
    SET_ROOM_DATA: 'SET_ROOM_DATA',
    SET_ADMIN_MAGIC_PASSWORD: 'SET_ADMIN_MAGIC_PASSWORD',
    SET_AUTO_CHECK_GUESS: 'SET_AUTO_CHECK_GUESS',
    SET_ACCOUNT_STATE: 'SET_ACCOUNT_STATE',
}

