import React from "react"
import axios from "axios"
import { keyframes } from 'styled-components'
import styled from "styled-components"
import CanvasPlayer from './canvasPlayer';
import {Link} from "react-router-dom"

import trackEvent from "../TrackEvent"
import strokeCompress from "./StrokeCompress"
var Styling = require('./styling.json')

class Replay extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            word: "",
            clue: "",
            difficulty: "",
            width: 0,
            height: 0,
            drawerWidth:0,
            drawerHeight:0,
            backgroundColor: "#fefefe",
            copied: false,
            drawingStrokes: [],
            replayComplete: false,
            loaded: false,
        }
    }

    async componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        const id = this.props.match.params.id

        try{
            const apiData = await axios.get( `/api/replay/${id}`)
            this.setState({
                loaded: true,
                word: apiData.data.word,
                clue: apiData.data.clue,
                difficulty: apiData.data.difficulty,
                drawingStrokes: strokeCompress.inflate(apiData.data.strokes),
                drawerWidth: apiData.data.width,
                drawerHeight: apiData.data.height,
                backgroundColor: apiData.data.backgroundColor
            })

            trackEvent({
                category: "SinglePlayer", 
                action: "Replay",
                label: id,
            })

        } catch(err){
            console.log("Error", err)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions=()=> {
        let width = Math.min(document.documentElement.clientWidth, 600) - 50
        let height = document.documentElement.clientHeight - 250
        if (height > width) {
            height = Math.min(height, 1.5 * width - 50)
        } else {
            // width = Math.min(width, 1.5 * height)
        }
        console.log("Set W/H", width, height)
        this.setState({ width, height }) ;
    }


    countWords = (words) => {
        words = words.replace(/(^\s*)|(\s*$)/gi, "");
        words = words.replace(/[ ]{2,}/gi, " ");
        words = words.replace(/\n /, "\n");
        return words.split(' ').length;
    }

    replayComplete = () => {
        this.setState({
            replayComplete: true,
        })

        setTimeout(() => {
            if (this.playWrapper) {
                this.playWrapper.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000)
    }

    scaleFromWidthHeight = (width, height) => {
        const screen_width = this.state.width
        const screen_height = this.state.height
        const w_diff = width / screen_width
        const h_diff = height / screen_height
        let scale
        if (w_diff > h_diff) {
            scale = 1.0 / w_diff
        } else {
            scale = 1.0 / h_diff
        }
        return scale
    }

    getCurrentWidthHeightScale = () => {
        const { drawerWidth, drawerHeight } = this.state

        const scale = this.scaleFromWidthHeight(drawerWidth, drawerHeight)

        return {
            width: drawerWidth, height: drawerHeight, scale
        }
    }

    render(){
        const { clue, word, drawingStrokes, difficulty } = this.state
        let diff_col = "#6bc558"
        if (difficulty === "Medium") {
            diff_col = "#ffc629"
        }
        else if (difficulty === "Hard") {
            diff_col = "#e7362e"
        }
        const num_words = this.countWords(word)
        const clue_wording = clue ? "Clue:" : "No clue"

        const { width, height, scale } = this.getCurrentWidthHeightScale()
        
        return (
            <div>
                <Title>
                    Guess the Answer
                </Title>
                {this.state.loaded &&
                    <React.Fragment>
                        <WatchingGrid>
                            <div>
                                {num_words === 1 ? <React.Fragment> <WordClueSmall>{`${clue_wording} ${clue}`}</WordClueSmall>
                                    <WordClueSmall>(1 word)</WordClueSmall> </React.Fragment> :
                                    <React.Fragment><WordClueSmall>{`${clue_wording} ${clue}`}</WordClueSmall>
                                        <WordClueSmall>{`(${num_words} words)`}</WordClueSmall></React.Fragment>
                                }
                            </div>
                            <Difficulty color={diff_col}> Difficulty: {difficulty} </Difficulty>
                            <div>
                            </div>
                        </WatchingGrid>
                        {/* <ReplaySpace>
                            {this.state.replayComplete &&
                                <ReplayStyle color={Styling.Gradients.Green}>
                                    {word}
                                </ReplayStyle>
                            }
                        </ReplaySpace> */}
                        <CanvasPlayer strokes={drawingStrokes} width={width} height={height} scale={scale}
                            onComplete={this.replayComplete} speed={0.5}
                            active_background_color = {this.state.backgroundColor} />
                    </React.Fragment>
                }
                {this.state.replayComplete &&
                <div ref={el => this.playWrapper = el} >
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <PlayNow color={Styling.Gradients.YellowTheme}>
                            PLAY NOW! 
                        </PlayNow>
                    </Link>
                </div>
                }
               
            </div>

        )
    }

}

export default Replay


// const Animation3 = keyframes`
//  0% {  opacity: 0; transform: scale(1.35) }
//  16% { opacity: 0; transform: scale(1.35) }
//  61% { opacity: 1;  transform: scale(0.9) }
//  100% {  transform: scale(1.0)}
// `


const Animation2 = keyframes`
 0% {  opacity: 0; transform: scale(0.5) }
 52% { opacity: 0; transform: scale(0.5) }
 75% { opacity: 1;  transform: scale(1.2) }
 100% {  transform: scale(1.0)}
`

const PlayNow = styled.div`
    cursor: pointer;
    border: solid #ffc629 2px;
    border-radius: 10px;
    padding: 5px;
    margin: 0px auto;
    margin-top:10px;
    width: 250px;
    color:white;
    font-family: 'Baloo Chettan 2', cursive;
    background: ${props => props.color};
    transform: scale(1.0);
    animation-name: ${ Animation2};
    animation-duration: 2.1s;
    /* animation-delay: 1s; */
`
const Title = styled.div`
    margin-bottom: 20px;
    font-size: 25px;
    color: #2958FF;
    font-family: 'Baloo Chettan 2', cursive;
`
const WatchingGrid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 10px 0px;
`

const Difficulty = styled.div`
    margin: 0px;
    border-radius: 10px;
    font-size: 18px;
    color: ${props => props.color};
    font-family: 'Baloo Chettan 2', cursive;
`

const WordClueSmall = styled.div`
    margin: 0px;
    color: #a9a9a9;
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Baloo Chettan 2', cursive;
`

// const ReplaySpace = styled.div`
//   min-height:50px;
// `

// const ReplayStyle = styled.span`
//     font-family: 'Baloo Chettan 2', cursive;
//     animation-name: ${ Animation3};
//     animation-duration: 1.0s;
//     margin: 0px auto;
//     /* background: ${props => props.color}; */
//     padding: 5px 10px;
//     border-radius: 10px;
//     border: solid 1px #859df1;
//     color: #2958FF;
//     /* width: 250px; */
// `