import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

const checkpoints = [120, 90, 60, 30, 20, 10, 5, 4, 3, 2, 1, 0]

class Timer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            timeLeft : props.seconds
        }
    }

    componentDidMount(){
        this.interval = setInterval( ()=>{
            this.setState( (s)=>({
                timeLeft: s.timeLeft - 1
            }), ()=>{
                if (this.state.timeLeft <= 0){
                    this.props.onTimeout()
                    if (this.interval){
                        clearInterval(this.interval)
                        this.interval = null
                    }
                }
            } )
        }, 1000 )
    }

    componentWillUnmount(){
        if (this.interval){
            clearInterval(this.interval)
        }
    }

    render(){
        const isCheckpoint = checkpoints.findIndex(v => v === this.state.timeLeft) !== -1
        let timeString
        if (this.state.timeLeft >= 60) {
            timeString = Math.trunc(this.state.timeLeft / 60) + ":" + ((this.state.timeLeft) % 60).toString().padStart(2, '0')
        } else {
            timeString = this.state.timeLeft.toString()
        }

        return (
            <Wrapper>
                <TimeStyle color={ this.state.timeLeft <= 3 ? "red" : "black" }> {timeString} </TimeStyle>
            
                {isCheckpoint &&
                    <CheckpointWrapper key={timeString}>
                        <Checkpoint>
                            {timeString}
                        </Checkpoint>
                    </CheckpointWrapper>
                }

            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    text-align: right;
    user-select: none;
    pointer-events:none;
`

const TimeStyle = styled.div`
    font-family: "Nanum Gothic", sans-serif;
    font-size: 20px;
    color: ${props=>props.color};
`;

const pulseAnimation = keyframes`
 0% { opacity: 0; transform: scale(0.5) }
 20% { opacity: 1}
 80% { opacity: 1; transform: scale(1.0) }
 100% { opacity: 0; transform: scale(0.9)}
`

const CheckpointWrapper = styled.div`
    z-index: 100000;
    position: fixed;
    text-align: center;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgba(0,0,0,0.1);
    border-radius: 40px;
    padding: 0px 18px;
    min-width: 200px;
`

const Checkpoint = styled.div`    
    opacity: 0;
    transform: scale(1.0);
    color: red;
    font-size: 120px;
    animation-name: ${pulseAnimation};
    animation-duration: 1s;
    /* animation-iteration-count: infinite */
`


export default Timer