import ActionTypes from "./actionTypes"

const setName = data => ({
    type: ActionTypes.SET_NAME,
    payload: data,
})

const setRoom = data => ({
    type: ActionTypes.SET_ROOM,
    payload: data,
})

const addStroke = data => ({
    type: ActionTypes.ADD_STROKE,
    payload: data,
})

const clearStrokes = data => ({
    type: ActionTypes.CLEAR_STROKES,
    payload: data
})

const setMute = data => ({
    type: ActionTypes.SET_MUTE,
    payload: data,
})

const setCustomCategories = data => ({
    type: ActionTypes.SET_CUSTOM_CATEGORIES,
    payload: data,
})

const setRoomData = data => ({
    type: ActionTypes.SET_ROOM_DATA,
    payload: data,
})

const setAdminMagicPassword = data => ({
    type: ActionTypes.SET_ADMIN_MAGIC_PASSWORD,
    payload: data,
})

const setAutoCheckGuess = data => ({
    type: ActionTypes.SET_AUTO_CHECK_GUESS,
    payload: data,
})

const setAccountState = data => ({
    type: ActionTypes.SET_ACCOUNT_STATE,
    payload: data,
})

export default {
    setName,
    setRoom,
    addStroke,
    clearStrokes,
    setMute,
    setCustomCategories,
    setRoomData,
    setAdminMagicPassword,
    setAutoCheckGuess,
    setAccountState,
}

