import React, { Component } from 'react';

class CanvasPlayer extends Component {

    constructor(props){
        super(props)
        this.state = {
            lastStrokeDrawnIndex : -1
        }
    }

    animate=(timestamp)=>{
        if (!this.lastTime){
            this.lastTime = timestamp
        }
        
        const ms = timestamp - this.lastTime
        this.lastTime = timestamp

        const numStrokes = this.props.strokes.length

        let speed = this.props.speed ? this.props.speed : 1.0

        let MAX_REPLAY_TIME = 7 * (1.0/speed) * 1000 // 7 Seconds
        
        const MIN_PER_STROKE_TIME = 2.5 * (1.0 / speed)

        const maxTimePerStroke = MAX_REPLAY_TIME / numStrokes

        const msPerStoke = Math.min( MIN_PER_STROKE_TIME, maxTimePerStroke )

        const numStrokesToDraw = Math.round( Math.max(ms / msPerStoke, 1) )

        // console.log("Time passed", ms, maxTimePerStroke, msPerStoke, "drawing", numStrokesToDraw)

        if (this.ctx){
            // Draw the strokes
            for(var ii=0;ii<numStrokesToDraw;++ii){
                let strokeIndex = this.state.lastStrokeDrawnIndex + ii
                if (strokeIndex >= 0 && strokeIndex < this.props.strokes.length){
                    // console.log("Drawing", strokeIndex)
                    const s = this.props.strokes[strokeIndex]
                    this.ctx.beginPath();
                    this.ctx.strokeStyle = s.c;
                    this.ctx.moveTo(s.m[0]*this.props.scale, s.m[1]*this.props.scale);
                    this.ctx.lineTo(s.l[0]*this.props.scale, s.l[1]*this.props.scale);
                    this.ctx.lineWidth = s.w * this.props.scale;
                    this.ctx.stroke();
                }
            }

            const maxIndex = this.props.strokes.length - 1

            this.setState( s=>{
                const nextIndex = s.lastStrokeDrawnIndex + numStrokesToDraw
                if (nextIndex >= maxIndex){
                    cancelAnimationFrame(this.animationLoop)
                    if (this.props.onComplete){
                        this.props.onComplete()
                    }
                    this.animationLoop = null
                } else {
                    this.animationLoop = requestAnimationFrame(this.animate)
                }
                return {
                    lastStrokeDrawnIndex : Math.min(maxIndex, s.lastStrokeDrawnIndex + numStrokesToDraw)
                }
            })
        } else {
            this.animationLoop = requestAnimationFrame(this.animate)
        }
    }

    componentDidMount(){
        this.animationLoop = requestAnimationFrame(this.animate)
        this.canvas.width = this.props.width *this.props.scale //320;
        this.canvas.height = this.props.height * this.props.scale //520;
        // this.ctx = this.canvas.getContext('2d');
        this.ctx.lineJoin = 'round';
        this.ctx.lineCap = 'round';
        this.ctx.lineWidth = this.props.active_brush;
        this.ctx.fillStyle = this.props.active_background_color;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);        
    }

    componentWillUnmount(){
        if (this.animationLoop){
            cancelAnimationFrame(this.animationLoop)
        }
    }

    saveContext=({context,canvas})=>{
        this.ctx = context
        this.canvas = canvas
    }
   
    render() {
        return (
            <PureCanvas contextRef={this.saveContext}
                style={{ background: 'white', borderStyle:'solid', borderColor:'rgb(210,210,210)',
                borderWidth:'4px', borderRadius:'5px', marginLeft:'0px'}}
            /> 
        )
    }
}

class PureCanvas extends React.Component {
    shouldComponentUpdate() {
      return false;
    }
  
    render() {
      return (
        <canvas
          ref={elem =>
            elem ? this.props.contextRef({canvas:elem, context:elem.getContext('2d')}) : null
          }
        />
      );
    }
  }
export default CanvasPlayer;
