import React, {useEffect} from 'react'
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"

import {connect} from 'react-redux'
import Actions from "../state/actions";
import Lottie from "../Lottie";
import * as animationData from "../animations/party_penguin.json"

import SoundButton from './soundButton'

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { keyframes } from 'styled-components'

// import MockServerTimer from './mockServerTimer'


const playOnePlayer = (props) =>{
    return(
    <React.Fragment>
        < OnePlayerStyle >
        <Or>  OR </Or>
        <SoundButton text="1-Player" color="primary" onClick={props.onOnePlayer} style={{ margin: "10px 0px" }} />
        </OnePlayerStyle >
    </React.Fragment>
    )
}

const publicRoom = (room, props, name, setName, setRoom) => {
    return (
        <React.Fragment>

            {/* <MockServerTimer start={11}/> */}
            <form onSubmit={e => {
                e.preventDefault()
                if (name.trim() && room.trim()) {
                    props.onJoin()
                }
            }}>
               
                <RoomID>
                    Enter your name and the room name for your group
            </RoomID>
                {props.userNameTaken ? <UserNameTaken> {` ${name} is taken for room ${room}`} </UserNameTaken> : null}
                <Text>
                    <TextField
                        variant="outlined"
                        name='name'
                        inputProps={{
                            maxLength: 15,
                        }}
                        style={{ margin: "10px 0px" }}
                        onChange={e => {
                            setName(e.target.value)
                            props.onNewUserName()
                        }}
                        value={name}
                        label="Name" />
                </Text>
                <Text>
                    <TextField
                        variant="outlined"
                        name='room ID'
                        inputProps={{
                            maxLength: 20,
                        }}
                        style={{ margin: "10px 0px" }}
                        onChange={e => setRoom(e.target.value)}
                        value={room}
                        label="Room Name" />
                </Text>
                <SoundButton text="Join" />
            </form>
            

           

        </React.Fragment>
    )
}


const Join =(props)=>{
    const {name,setName, room, setRoom, isAuthenticated, isExpired} = props
    const [joinPublicRoom, setJoinPublicRoom] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    const authenticatedLanding=()=>{
        return (
            <div style={{ padding: "20px 0px" }}>

                { isExpired ? 
                    <div><PrivateSubscribeWrapper >
                        <Instructions style={{ color: "white" }}>
                            Your free trial of 
                            <span style={{ color: "#ffc629" }}> DRAWPOLY </span>
                            <span style={{ color: "#2958FF" }}> PRO </span>
                             has expired!
                        </Instructions>
                        <Instructions style={{ color: "white", fontSize: "18px",
                            paddingTop:"0" }}>
                            Subscribe to join your private room 
                            <span style={{ color: "#ffc629" }}> {room}</span>
                        </Instructions>
                        <SoundButton text="Subscribe" color="secondary" onClick={() => {
                            props.onSubscribe()
                        }} />
                        
                    </PrivateSubscribeWrapper></div>
                :
                <PrivateWrapper >
                    <Instructions style={{ color: "rgb(80,80,80)" }}>
                        Join your private room
                    </Instructions>
                    <SoundButton text={room} color="secondary" onClick={()=>{
                        props.onJoin()
                    }}/>
                </PrivateWrapper>
                }

                <OnePlayerStyle>
                    {/* <Or style={{padding:"10px 0px"}}>  OR </Or> */}
                </OnePlayerStyle>

                <PublicWrapper >
                    <Instructions >
                        Join another room
                    </Instructions>
                    <SoundButton text="Join" color="primary"
                        onClick={() => { 
                            setJoinPublicRoom(true) 
                        }} />
                </PublicWrapper >
                {playOnePlayer(props)}
            </div>
        )
    }

    const authenticatedPublic=()=>{
        return (
            <div style={{ padding: "20px 0px" }}>
                <IconButton style={{ position: "absolute", left: "10px", top: "10px" }} onClick={() => 
                { setJoinPublicRoom(false) }}>
                    <ArrowBackIosIcon style={{ color: "rgb(200,200,200)" }} />
                </IconButton>

                <Instructions >
                    Join
                </Instructions>
                {publicRoom(room, props, name, setName, setRoom)}
            </div>
        )
    }

    const unathenticatedPublic=()=>{
        return (
            <div>
                {/* <Pro style={{  left: "auto", padding: "10px", textAlign: "right",
                    background: "yellow" }} >
                    <Anim>  
                    <div> TRY  <span style={{ color: "#2958FF" }}>PRO</span> </div> 
                        <div style={{ fontSize: "13px", paddingBottom: "10px", color: "rgb(190,190,190)" }}> 
                        Custom words, private rooms + more! </div> </Anim>

                <SoundButton text={"Sign Up"} color="secondary" 
                    style={{  background: "none", color:"rgb(150,150,150)",
                        fontSize: "10px" }} 
                        onClick={()=>props.onSignup()}
                        />
                <SoundButton text={"Log In"} color="secondary" 
                    style={{ background: "none", color: "rgb(150,150,150)",
                        fontSize: "10px"}} 
                        onClick={()=>props.onLogin()}
                        />
                </Pro> */}
                <Instructions>
                    Join
                </Instructions>
                {publicRoom(room, props, name, setName, setRoom)}
                {playOnePlayer(props)}
                <TofS>
                    By clicking Join or 1-Player, you agree to our {" "}
                    <a style={{ color: "rgb(200, 200, 200)" }} href="https://drawpoly.com/termsofservice" rel="noopener noreferrer" target="_blank">Terms of Use</a>
                    {" "} and {" "}
                    <a style={{ color: "rgb(200, 200, 200)" }} href="https://drawpoly.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                </TofS>
            </div>
        )
    }
    
    return (
        <div style={{ background: 'white' }}>
        { (isAuthenticated && joinPublicRoom) && authenticatedPublic() }
        { (isAuthenticated && !joinPublicRoom) && authenticatedLanding()}
        { !isAuthenticated && unathenticatedPublic() }
            <AnimProgress>
                <Lottie animation={animationData.default} loop={true} />
            </AnimProgress>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        name : state.name,
        room : state.room,
    }
}
const mapDispatchToProps = {
    setName : Actions.setName,
    setRoom : Actions.setRoom,
}
export default connect(mapStateToProps, mapDispatchToProps)( Join )

const pulseAnimation = keyframes`
 0% { transform: scale(1.0); }
 5% { transform: scale(1.1);  }
 10% { transform: scale(1.0);  }
 15% { transform: scale(1.1);  }
 20% { transform: scale(1.0); }
`

const Pro = styled.div`
    letter-spacing: 2px;
    color: #ffc629;
    font-size: 18px;
    font-family: 'Baloo Chettan 2', cursive;
`

const Anim = styled.div`
    animation-name: ${pulseAnimation};
    animation-duration: 5.0s;
    animation-iteration-count: 2;
    transform: scale(1.0);
`

const PrivateSubscribeWrapper = styled.div`
    margin: 0px 25px;
    padding: 20px;
    text-align: center;
    font-family: 'Baloo Chettan 2', cursive;
    color:white;
    border-radius: 5px;
    background: linear-gradient(rgb(120,120,120), rgb(190,190,190));
`

const PrivateWrapper = styled.div`
    margin: 0px 25px;
    padding: 20px;
    text-align: center;
    font-family: 'Baloo Chettan 2', cursive;
    color:rgb(150,150,150);
    border-radius: 5px;
    background: linear-gradient(rgb(235,235,235), rgb(250,250,250));
`

const PublicWrapper = styled.div`
    margin: 0px 25px;
    padding: 20px;
    text-align: center;
    font-family: 'Baloo Chettan 2', cursive;
    color:rgb(150,150,150);
    border: solid rgb(240,240,240) 2px;
    border-radius: 5px;
`

const Text = styled.div`
    margin: 10px;
`

const AnimProgress = styled.div`
margin: 0 auto;
width: 100%;
margin-top: -20px;
`

const UserNameTaken = styled.div`
    margin: 20px;
    font-size: 12px;
    color: red;
`

const TofS = styled.div`
    user-select:none;
    margin: 20px 5px;
    margin-bottom: 0px;
    font-size: 12px;
    color:rgb(200,200,200);
    /* font-family: 'Baloo Chettan 2', cursive; */
`

const Instructions = styled.div`
    user-select:none;
    margin: 10px;
    padding: 20px 0px;
    font-family: 'Baloo Chettan 2', cursive;
    color: rgb(190,190,190);
    font-size: 22px;
`

const Or = styled.div`
    margin: 20px 0px;
    user-select:none;
`

const OnePlayerStyle = styled.div`
    font-size: 16px;
    color:rgb(200,200,200);
    font-family: 'Baloo Chettan 2', cursive;
    /* border: solid 2px #f1f1f1; */
    /* border-radius: 10px; */
    padding: 15px 5px;

`

const RoomID = styled.div`
    user-select:none;
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 13px;
    color:rgb(190,190,190);
    font-family: 'Baloo Chettan 2', cursive;
`