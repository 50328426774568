import React from 'react'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';


class MagicWord extends React.Component{
    constructor(props){
        super(props)
        this.state={
            magicWord : "",
            showEdit: false,
        }
    }
    submit=(e)=>{
        e.preventDefault()
        let {magicWord} = this.state

        // if (magicWord.trim().length > 0){
        this.setState({showEdit:false})
        this.props.setMagicPassword(magicWord.trim())
        // }
    }

    closeDialog=()=>{
        this.setState({showEdit:false})
    }

    render(){

        const hasPassword = this.props.password.length > 0

        return ( 
        <MagicWordWrapper>
                {/* <RoomStateText>
                    Room State: { hasPassword ? "Protected" : "Public" }
                </RoomStateText> */}
            
            {hasPassword ?
                <div>
                    <RoomStateText style={{color:"rgb(180,180,180"}}>
                        Room State: {hasPassword ? "Protected" : "Public"}
                    </RoomStateText>
                     <MagicWordText>
                        Magic Word: {this.props.password}
                    </MagicWordText>
                
                <Button variant="contained" color="secondary" style={{ height: "40px", margin: "5px",
                            fontWeight: "bold" }}
                    onClick={()=>{
                        this.setState({
                            showEdit:true
                        })
                    }}
                    >
                    Edit magic word
                </Button>
                </div>
                :
                <div>
                    <RoomStateText>
                        Room State: {hasPassword ? "Protected" : "Public"}
                    </RoomStateText>
                <Button variant="contained" color="secondary" style={{ height: "40px", margin: "5px",
                        fontWeight: "bold" }}
                    onClick={()=>{
                        this.setState({
                            showEdit:true
                        })
                    }}
                    >
                    Set magic word
                </Button>
                </div>
            }
            <Dialog open={this.state.showEdit}>
            <form onSubmit={this.submit}>
                <Wrapper>
                    <Text>
                        Players must enter this password to play
                    </Text>
                    <TextField
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        name='magicPassword'
                        onChange={e => this.setState({
                            magicWord: e.target.value.toLowerCase()
                        })}
                        value={this.state.magicWord}
                        style={{ padding: "10px 0px" }}
                        autoFocus
                        label="Magic Word" />
                    <ActionsWrapper>
                        <Button style={{color:"rgb(150,150,150)"}} 
                            onClick={this.closeDialog} >
                            Cancel
                        </Button>
                                <Button style={{ color: "#ffc629", background: "white", border:"#2958ff", fontWeight: "bold"}} 
                            onClick={this.submit} 
                            type="submit" variant="outlined">
                            Save
                        </Button>
                    </ActionsWrapper>
                </Wrapper>
            </form>
            </Dialog>
            </MagicWordWrapper> )
    }
}

const RoomStateText = styled.div`
    color: #2958FF;
    /* font-size: 25px; */
    font-family: 'Baloo Chettan 2', cursive;
    
`

const MagicWordText = styled.div`
    color: #2958FF;
    font-size: 23px;
    /* font-size: 25px; */
    font-family: 'Baloo Chettan 2', cursive;
    margin: 10px 0px;
`

const Wrapper = styled.div`
    padding: 20px;
    text-align: center;
    font-family: 'Baloo Chettan 2', cursive;
    color:rgb(150,150,150);
    /* font-size: 15px; */
`

const MagicWordWrapper = styled.div`
    margin: 20px;
    margin-top: 50px;
    padding: 10px;
    text-align: center;
    font-family: 'Baloo Chettan 2', cursive;
    color:rgb(150,150,150);
    border: solid rgb(230,230,230) 2px;
    border-radius: 5px;
    background: linear-gradient(rgb(230,230,230), rgb(240,240,240));
    /* font-size: 15px; */
`

const Text = styled.div`
    margin-bottom: 20px;
`

const ActionsWrapper = styled.div`
    text-align: right;    
`

export default MagicWord