import React, {useState} from 'react'
import styled from 'styled-components'

import { keyframes } from 'styled-components'

import useSound from 'use-sound'
import sound from "../sounds/timer.mp3"
import {connect} from 'react-redux'

const checkpoints = [120, 90, 60, 30, 20, 10, 5, 4, 3, 2, 1, 0]

const ServerTimer =(props)=>{

    const [play] = useSound(sound, {soundEnabled:!props.mute, html5:true})
    const [lastCheckpoint, setLastCheckpoint] = useState()

    const {time} = props

    if (time < 0){
        return <Wrapper></Wrapper>
    }

    const isCheckpoint = checkpoints.findIndex(v=>v===time) !== -1
    let timeString
    if (time >= 60){
        timeString = Math.trunc(time / 60) + ":" + ((time) % 60).toString().padStart(2, '0')
    } else {
        timeString = time.toString()
    }

    if (isCheckpoint && lastCheckpoint !== time ){
        play()
        setLastCheckpoint(time)
    } 

    return (
        <Wrapper>

            <NormalWrapper>
                {timeString}
            </NormalWrapper>    

            {isCheckpoint &&
                <CheckpointWrapper key={timeString}>
                    <Checkpoint>
                        {timeString}
                    </Checkpoint>
                </CheckpointWrapper>
            }

        </Wrapper>
        
    )
}

const Wrapper = styled.div`
    /* height: 50px; */
    text-align: right;
    user-select:none;
    pointer-events:none;
    /* font-family: 'Baloo Chettan 2', cursive, monospace; */
    font-family: 'Courier Prime', monospace;
    color: rgb(150,150,150);
`

const NormalWrapper = styled.div`
    /* color: black; */
    font-size: 18px;
`

// https://medium.com/@matt.readout/adding-css-animations-with-styled-components-6c191c23b6ba
const pulseAnimation = keyframes`
 0% { opacity: 0; transform: scale(0.5) }
 20% { opacity: 1}
 80% { opacity: 1; transform: scale(1.0) }
 100% { opacity: 0; transform: scale(0.9)}
`

const CheckpointWrapper = styled.div`
    z-index: 100000;
    position: fixed;
    text-align: center;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgba(0,0,0,0.1);
    border-radius: 40px;
    padding: 0px 18px;
    min-width: 200px;
`

const Checkpoint = styled.div`    
    opacity: 0;
    transform: scale(1.0);
    color: red;
    font-size: 120px;
    animation-name: ${pulseAnimation};
    animation-duration: 1s;
    /* animation-iteration-count: infinite */
`


const mapStateToProps = state => ({mute : state.mute})
export default connect(mapStateToProps)( ServerTimer )