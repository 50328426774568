import React from "react"

import Button from '@material-ui/core/Button';
import useSound from 'use-sound'

// FX //
import plunger from "../sounds/plunger.mp3"

import {connect} from 'react-redux'

const SoundButton =(props)=>{

    const [play] = useSound(plunger, {soundEnabled:!props.mute, html5:true})

    const buttonProps = {
        variant : props.variant || "contained",
        type: props.type || "submit",
        color: props.color || "secondary",
        style:{ 
            height: "40px", 
            margin: "0px 0px", 
            fontWeight: 'bolder',
            ...props.style
        },
    }

    return <Button {...buttonProps} onClick={()=>{
        play()
        if (props.onClick) props.onClick()
    }} > {props.text} </Button>
}

const mapStateToProps = state => ({mute : state.mute})
export default connect(mapStateToProps)( SoundButton )